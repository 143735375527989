<template>
<div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{mode}} {{item.Broj}}</h1>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>

        <div class="row mb-2">
          <div class="col-sm-2">
            <b-button block variant="primary" @click="save">
              <span class="menuButton fas fa-save"></span>
              <span>&nbsp;Spremi</span>          
            </b-button>            
          </div>
          <div class="col-sm-7 menuButtonOthers">
            <b-button variant="outline-secondary" @click="print" v-if="!isNew">
              <span class="menuButton fas fa-print"></span>
              <span>Ispis</span>          
            </b-button>      
            <b-button variant="outline-secondary" @click="email" v-if="!isNew">
              <span class="menuButton far fa-envelope"></span>
              <span>E-mail</span>          
            </b-button>
            <b-button variant="outline-secondary" @click="dokumentupload">
              <span class="menuButton fas fa-paperclip"></span>
              <span>Dodaj privitak</span>          
            </b-button>      
            <!-- <b-button variant="outline-secondary" @click="valuta" v-if="!isNew && isEditable">
              <span class="fas fa-euro-sign"></span>
              <span title="Ažuriraj stavke koje imaju cijenu u valuti sa najnovijim tečajem">&nbsp;Ažuriraj valutu</span>          
            </b-button>   -->
            <b-button variant="outline-secondary" @click="otpremnicaDo" v-if="!isNew && hasArtkleKojiNisuUsluge">
              <span class="fas fa-truck"></span>
              <span title="Napravi otpremnicu za stavke koje nisu usluge">&nbsp;Otpremnica</span>          
            </b-button>              
            <b-button variant="outline-secondary" @click="showLog" v-if="!isNew">
              <span class="fas fa-history"></span>
              <span title="Povijest dokumenta (log)">&nbsp;Log</span>          
            </b-button>                                                 
          </div>     
          <div class="col-sm-2">
        
          </div>                
        </div>

        <div class="row mb-2">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <label for="partner">Partner</label>
                  <vue-typeahead-bootstrap id="partner"
                  placeholder="upišite dio naziva partnera"
                  v-model="partnerquery"
                  :data="partneri"
                  @input="updatePartnerItems"
                  :serializer="item => item.Naziv + ' ' + item.Mjesto"
                  @hit="displayPartner = $event"
              />
              <!-- <br>
              {{ partner.Adresa }} {{ partner.Email }} -->
              </div>
            </div>
          </div>
          <div class="col-sm-4">

          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="DatumDokumenta">Datum dokumenta</label>
                    <date-picker class="col-sm-5 col-md-4" id="DatumDokumenta" ref="DatumDokumenta"
                    :value="item.DatumDokumenta" 
                    @dp-change="updateFakturaHeaderItem('DatumDokumenta', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="ValutaDokumenta">Valuta dokumenta</label>
                    <date-picker class="col-sm-5 col-md-4" id="ValutaDokumenta"
                    :value="item.DatumValute" 
                    @dp-change="updateFakturaHeaderItem('DatumValute', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>                  
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="Veza">Veza</label>
                    <b-form-input v-model="item.Veza"></b-form-input>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="Veza">Dodatni tekst</label>
                    <b-form-textarea rows="3" max-rows="3" v-model="item.TekstDodatno"></b-form-textarea>
                  </div>
                </div>                
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <DokumentPrilog ref="dokumentPrilogComponent"  vrstadokumenta="faktura" v-if="this.files.length != 0" :items="files"></DokumentPrilog>
                  </div>
                </div>            
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">            
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznos">Iznos</label>
                    <b-form-input class="col-sm-7 text-right" id="iznos" readonly :value="currencyFormatter(item.Iznos)"></b-form-input>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznosrabat">Iznos rabata</label>
                    <b-form-input class="col-sm-7 text-right" id="iznosrabat" readonly :value="currencyFormatter(item.IznosRabat)"></b-form-input>
                  </div>
                </div>
               <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznosporez">Iznos poreza</label>
                    <b-form-input class="col-sm-7 text-right" id="iznosporez" readonly :value="currencyFormatter(item.IznosPDV)"></b-form-input>
                  </div>
                </div>    
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="ukupno">Ukupno</label>
                    <b-form-input class="col-sm-7 text-right" id="ukupno" readonly :value="currencyFormatter(item.Ukupno)"></b-form-input>
                  </div>
                </div>           

                <div class="row mb-2">
                  <div class="col-sm-12">
                    <b-button variant="outline-secondary" @click="printOtpremnica" v-show="otpremnicaID != '00000000-0000-0000-0000-000000000000'">
                      <span class="menuButton fas fa-print"></span>
                      <span>Ispis otpremnice</span>          
                    </b-button>    
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </div><!-- /.container-fluid -->
    </section>
    <section class="content">
        <div class="container-fluid">
            <FakturaStavkaEdit ref="stavkeComponent"></FakturaStavkaEdit>
        </div>
    </section>

    <EmailModal vrstadokumenta="faktura" ref="emailModal"></EmailModal>
    <DokumentLogModal ref="dokumentLogModal"></DokumentLogModal>
    <DokumentUploadModal ref="dokumentUploadModal" @uploadedFile="uploadedFileCallback"  vrstadokumenta="Faktura"></DokumentUploadModal>    
</div>
    
</template>

<script>
import datasource from "../_services/backend";
import * as utils from "../helpers/index";
import FakturaStavkaEdit from "./FakturaStavkaEdit.vue"
import EmailModal from "./EmailModal.vue"
import DokumentLogModal from "./DokumentLogModal.vue"
import DokumentUploadModal from "./DokumentUploadModal.vue"
import DokumentPrilog from "./DokumentPrilog.vue"

import store from "../libs/store";

import { debounce } from 'lodash'

import moment from "moment-timezone";

const fakture = datasource.get("faktura");
const partneri = datasource.get("partner");

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default({
    name: 'FakturaEdit',
    mounted() {
      moment.tz.setDefault("Europe/Zagreb");

      if (this.$route.params.id == "-1") {
        if (new Date().getFullYear() != store.state.poslovnagodina) {
          this.$set(this.item, 'DatumDokumenta', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
          this.$set(this.item, 'DatumValute', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
        } else {
          this.$set(this.item, 'DatumDokumenta', moment().local());
          this.$set(this.item, 'DatumValute',  moment().local());
        }

        this.$set(this.item, 'Veza', '');
        this.$set(this.item, 'TekstDodatno', '');

        this.updateTotal(0,0,0,0);

      } else {
          fakture.getOne(this.$route.params.id)
          .then(response => {
            this.$set(this, 'item', response.data['faktura']); 
            this.$set(this, 'files', response.data['files']); 

            this.$set(this.item, 'DatumDokumenta', moment(this.item.DatumDokumenta).local())
            this.$set(this.item, 'DatumValute', moment(this.item.DatumValute).local())

            this.partner = response.data['partner'];

            this.partner.toString = function() {
              return this.Naziv + " " + this.Mjesto;
            }
            this.partnerquery = this.partner.toString();

            this.isEditable = response.data['isEditable'];
            this.hasArtkleKojiNisuUsluge =  response.data['hasArtkleKojiNisuUsluge'];

            this.$set(this, 'otpremnicaID', response.data['OtpremnicaID']);
          });
      }

      unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
        if (mutation.type == 'updatePoslovnaGodina') {     
          if (store.state.poslovnagodinanorefreshonnextchange) {
            store.state.poslovnagodinanorefreshonnextchange = false;
            return;
          }
            

          if (!this.isNew) {
            this.$router.push("/faktura");
          }

          if (new Date().getFullYear() != store.state.poslovnagodina) {
            this.$set(this.item, 'DatumDokumenta', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
            this.$set(this.item, 'DatumValute', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
          } else {
            this.$set(this.item, 'DatumDokumenta', moment().local());
            this.$set(this.item, 'DatumValute',  moment().local());
          }
        }        
      });    

    },
    beforeRouteLeave(to,from,next) {
      unsubscribe();
      next();
    },
    computed: {
      displayPartner: {
        get: function() {
          return this.partner;
        },
        set: function(value) {
          this.partner = value;
          this.$set(this.item, "DatumValute", moment().local().add(this.partner.Valuta, 'days'));
        }
      },
      isNew: {
        get: function(){
          return this.$route.params.id == "-1"
        }
      },
      mode: {
        get: function() {
          if (this.isNew)
            return 'Nova faktura'    
          else 
            return 'Pregled fakture';
        }
      }
    },
    data() {
        return {
          isEditable: true, 
          partnerquery: '',
          partneri: [],
          item: {},
          partner: {},
          query: '',
          hasArtkleKojiNisuUsluge: false,
          otpremnicaID: '00000000-0000-0000-0000-000000000000',
          dpoptions: {
            useCurrent:false,
            format: 'DD.MM.YYYY.',
            locale: 'hr-HR',
          },
          files: []
        }
    },
    methods: {
      uploadedFileCallback(file) {
        this.files.push(file)
      },
      currencyFormatter: function(value) {
        return utils.currencyFormatter(value, this.item.DatumDokumenta);
      },
      currencyFormatter2: function(value) {
        return utils.currencyFormatter2(value);
      },
      valuta() {

      },
      print() {
        window.open("/api/faktura/print?id=" + this.item.FakturaID + "&token=" + JSON.parse(localStorage.getItem('user2')).token, "_blank");
      },
      printOtpremnica() {
        window.open("/api/faktura/printOtpremnica?id=" + this.otpremnicaID + "&token=" + JSON.parse(localStorage.getItem('user2')).token, "_blank");
      },
      email() {
        this.$refs['emailModal'].show();
        setTimeout(() => this.$refs['emailModal'].$refs['partneremail'].focus(), 500);
      },
      dokumentupload() {
        this.$refs['dokumentUploadModal'].show();
      },          
      showLog() {
        this.$refs['dokumentLogModal'].show();
      },
      otpremnicaDo() {
        fakture.otpremnica(this.item.FakturaID).then(response => {
            if (response.status == 200) {
              this.$vToastify.success("Dokument uspješno spremljen", "OK")
              console.log(response);
              this.$set(this, 'otpremnicaID', response.data);
            } else {
              console.log(response)
            }
        }).catch(error => {
          this.$vToastify.error("Greška prilikom spremanja dokumenta " + error.response.data, "Greška");       
        });
      },
      save() {       
        if (this.$refs.stavkeComponent.stavke.length == 0) {
          this.$vToastify.warning("Dokument mora imati minimalno jednu stavku", "Poruka");       
          return false; 
        }
        
        this.item.PartnerID = this.displayPartner.PartnerID;

        fakture.update({ 
          faktura: this.item, 
          stavke: this.$refs.stavkeComponent.stavke, 
          files: this.files,
          stavkeDeleted: this.$refs.stavkeComponent.stavkeDeleted }).then(response => {
            if (response.status == 200) {
              this.$vToastify.success("Dokument uspješno spremljen", "OK")

              if (this.isNew) {
                this.$set(this, 'item', response.data);
                this.$router.replace({path: '/faktura', name: 'faktura.edit', params: { id: response.data.FakturaID}});
                
                fakture.getOne(this.$route.params.id)
                .then(response => {                  
                  this.$set(this, 'item', response.data['faktura']); 
                  this.hasArtkleKojiNisuUsluge =  response.data['hasArtkleKojiNisuUsluge'];
                  this.$refs.stavkeComponent.refresh();
                });   
              } else {
                this.$refs.stavkeComponent.refresh()
              }
            } else {
              console.log(response);
            }            
          }).catch(error => {
            this.$vToastify.error("Greška prilikom spremanja dokumenta " + error.response.data, "Greška");       
          });
      },
      updateTotal(iznos, iznosrabat, iznospdv, ukupno) {
        this.$set(this.item, "Iznos", iznos);
        this.item.Osnovica = iznos;
        this.item.IznosRabat = iznosrabat;
        this.item.IznosPDV = iznospdv;
        this.item.Ukupno = ukupno;
      },
      updateFakturaHeaderItem(key, value) {
        this.item[key] = value;
      },
      updatePartnerItems: debounce(function() {      
        if (Object.keys(this.partner).length === 0 || !this.partnerquery.trim().toLowerCase().includes(this.partner.Naziv.toLowerCase()))
          partneri.get(this.partnerquery).then(response => this.partneri = response.data);        
      }, 500),    
    },
    components: {
      'FakturaStavkaEdit': FakturaStavkaEdit,
      'EmailModal': EmailModal,
      'DokumentLogModal': DokumentLogModal,
      'DokumentUploadModal': DokumentUploadModal,
      'DokumentPrilog': DokumentPrilog
    }
})
</script>
<style scoped>
.menuButton {
  margin-right: .2rem;
}
.menuButtonOthers button {
  margin-right: .5rem;
}
</style>