<template>    
  <b-modal size="xl" ok-only scrollable ref="dokumentLogModal" hide-backdrop title="Log za dokument"
  @ok="handleOK"  >

    <div class="row">
      <div class="col-sm-12">
        <b-table-lite :items="items"></b-table-lite>
      </div>
    </div>


  </b-modal>
</template>

<script>
import datasource from "../_services/backend";
const log = datasource.get("log");

export default({
  name: 'DokumentLogModal',
  mounted() {        

  },
  computed: {

  },
  data() {
    return {
      items: []
    } 
  },
  methods: {

    handleOK() {
      //this.$emit('placenoDatumSelected', this.selected);
    },
    handleCancel() {
      //this.$parent.cancelAddNew();
    },    
    show(id) {
      if (id == null)
        id = this.$route.params.id; 

      log.get(id)
      .then(response => {
        this.$set(this, 'items', response.data); 
      });

      this.$refs['dokumentLogModal'].show();
    },

  }
})
</script>

<style>
.modal-xl {
  max-height: 80vh !important;
}
</style>