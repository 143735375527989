import moment from 'moment';
import Vue from 'vue';

export * from './auth-header';
export * from './backend';

export const EventBus = new Vue();

export const euroThreshold = new moment(("2023-01-01"));
export const today = new moment();

export function currencyFormatter(value, datum) {
  if (!datum)
    datum = new moment();
  else
    datum = new moment(datum);
  
  if (isNaN(value))
    return "";   
  
  var formatter = new Intl.NumberFormat('hr-HR', {
    style: 'currency',
    currency: 'HRK',
    minimumFractionDigits: 2
  });

  if (datum >= euroThreshold)  
    return (formatter.format(value)).replace("HRK", "").replace("kn", "€");
  else
    return (formatter.format(value)).replace("HRK", "");
}

export function currencyFormatter2(value, valuta = null, datum) {
  if (isNaN(value))
    return ""; 
  
  if (!datum)
    datum = new moment();
  else
    datum = new moment(datum);
  
  var formatter = new Intl.NumberFormat('hr-HR', {
    style: 'currency',
    currency: valuta ? valuta : 'HRK',
    minimumFractionDigits: 2
  });

  if (valuta ? valuta : 'HRK' == 'HRK') {
    if (datum >= euroThreshold)  
      return (formatter.format(value)).replace("HRK", "").replace("kn", "€");
    else
      return (formatter.format(value)).replace("HRK", "");
  } else {
    return (formatter.format(value)).replace("HRK", "");
  }

  
}
