
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
//import VeeValidate from 'vee-validate';

import App from './App.vue'
import router from './router'
import store from './libs/store'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'admin-lte/dist/css/adminlte.css'

import { jwtInterceptor } from './helpers'
import { loggedoutInterceptor } from './helpers'

import { BootstrapVue } from 'bootstrap-vue'

import VueLuxon from 'vue-luxon';
import VueToastify from "vue-toastify";

import 'admin-lte/dist/js/adminlte.js'

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

//import 'nprogress/nprogress.css'
//import axios from 'axios'
//import NProgress from 'nprogress'
//import AxiosNProgress from 'axios-nprogress'

import { loadProgressBar } from 'axios-progress-bar'

loadProgressBar()

window.Vue = require('vue').default

Vue.config.productionTip = false

import datePicker from 'vue-bootstrap-datetimepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import 'axios-progress-bar/dist/nprogress.css'

import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(datePicker);
Vue.use(VueToastify,{
  position: "top-center", 
  theme: "light",
  successDuration: 2000,
  errorDuration: 4000,
});

Vue.use(VueLuxon, {
  input: {
      zone: "Europe/Zagreb",
      format: "iso",
      locale: 'hr'
  },
  output: "short"
});

Vue.use(BootstrapVue)

jwtInterceptor();
loggedoutInterceptor();

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })

/* eslint-disable no-new */
/* eslint-disable no-unused-vars */

new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit('initialiseStore');},
  render: h => h(App),
  el: "#app"
})

// if (store.state.user != null && router.currentRoute.path != "/dashboard")
//   router.push("/dashboard");
