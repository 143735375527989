<template>
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
            <b-table hover :items="stavke" :fields="fields" ref="selectableTable" 
            primary-key="FakturaStavkaID"                         
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="columnName"
            :sort-desc.sync="descendingSort"
            :no-sort-reset="true"          
            :fixed="true"
            >
            <template #cell(RedniBroj)="data">
              <div style="min-width: 40px;">
                {{data.value}}
                <span v-if="!stavke[data.index].isEdit" @click="deleteStavka(data)" style="cursor: pointer;" title="briši stavku" class="fas fa-trash-alt"></span>            
              </div>
            </template>            
            <template #head()="data">
              {{ data.label }}
            </template>
            <template #cell(artiklnaziv)="data">
              <span @click="updateNazivArtikla(data)" class="stavka-naziv" title="izmjena naziva artikla" >{{data.value}}</span>
              <span @click="artiklPickerHandler(data)" class="fas fa-edit" title="odabir artikla" style="margin-top:3px; float:right; cursor: pointer;"></span>
              <span @click="updateNazivArtikla(data)" class="stavka-dodatniopis" v-if="stavke[data.index].DodatniOpis != null">{{stavke[data.index].DodatniOpis}}</span>
            </template>

            <template #cell(Cijena)="data">
              <b-form-input v-if="stavke[data.index].isEdit && data.field.isEditable" 
              @focus="$event.target.select()"
              v-model="stavke[data.index][data.field.key]"
              @input="doStavkaCalc($event, data.field.key)"
              ></b-form-input>
              <b-form-input v-if="stavke[data.index].isEdit && data.field.isEditable" 
              @focus="$event.target.select()"
              v-model="stavke[data.index].Marža"
              placeholder="marža %"
              @input="doStavkaCalc($event, 'Marža')"
              ></b-form-input>              
              <span v-else @click="editCellHandler(data, data.field)">{{data.value}}</span>              
              <span  v-if="!stavke[data.index].isEdit" style="margin-left:5px;" v-text="maržaFormatter(stavke[data.index].Marža)"></span>
            </template>

            <template #cell(RabatPostotak)="data">
              <b-form-input v-if="stavke[data.index].isEdit && data.field.isEditable" 
              @focus="$event.target.select()"
              v-model="stavke[data.index][data.field.key]"
              placeholder="rabat %"
              @input="doStavkaCalc($event, data.field.key)"
              ></b-form-input>
              <span v-else @click="editCellHandler(data, data.field)">{{data.value}}</span>
              <span style="margin-left:5px;" v-text="rabatCurrencyFormatter(stavke[data.index].RabatIznos)"></span>
              <span class="previewitem"
              v-text="currencyFormatter(currenteditrow['previewRabatIznos'])" v-if="currenteditrow != null && data.field.showPreview && data.index === currenteditrow.index"></span>
            </template>

            <template #cell()="data">
              <b-form-input v-if="stavke[data.index].isEdit && data.field.isEditable" 
              @focus="$event.target.select()"
              v-model="stavke[data.index][data.field.key]"
              @input="doStavkaCalc($event, data.field.key)"
              ></b-form-input>
              <span v-else @click="editCellHandler(data, data.field)">{{data.value}}</span>
              <span class="previewitem"
              v-text="currencyFormatter(currenteditrow['preview'+data.field.key])" v-if="currenteditrow != null && data.field.showPreview && data.index === currenteditrow.index"></span>
            </template>

            <template #cell(edit)="data">
            <b-button variant="success" v-if="currenteditrow != null && data.index === currenteditrow.index" @click="editCommitRowHandler(currenteditrow.index)">
              <span>Done</span>          
            </b-button>
            <b-button variant="outline-primary" v-if="currenteditrow != null && data.index === currenteditrow.index" @click="cancelEditRowHandler(currenteditrow.index)">
              <span>Cancel</span>          
            </b-button>     
            </template> 
          </b-table>        
        </div>
      </div>

      <div class="row ">
        <div class="col-sm-2">
          <!-- v-if="this.$parent.isEditable" -->
          <b-button variant="success" @click="addNewStavka">
            <span>Dodaj stavku</span>          
          </b-button>
        </div>
      </div>
    </div>                  
  </div>

  <ArtiklModal @artiklselected="updateArtiklCallback" ref='artiklPicker'></ArtiklModal>
  <ArtiklNazivModal @nazivchange="updateArtiklNazivCallback" ref='artiklNazivModal'></ArtiklNazivModal>
</div>    
    
</template>

<script>
import datasource from "../_services/backend";
import ArtiklModal from './ArtiklModal.vue';
import ArtiklNazivModal from './ArtiklNazivModal.vue';

import * as utils from "../helpers/index";

const fakturastavke = datasource.get("fakturastavka");
const tecaj = datasource.get("tecaj");

export default({
    name: 'FakturaStavkaEdit',
    mounted() {
      if (this.$route.params.id) {
          this.refresh();
      }           
    },
    getters: {

    }, 
    computed: {

    },
    methods: {
      handleHover(hovered) {
        this.isHovered = hovered
      },
      refresh() {
        fakturastavke.getAll(this.$route.params.id).then(response => this.stavke = response.data)
      },
      addNewStavka() {

        //ako smo u editu ne reagiraj na addnew
        if (this.currenteditrow != null)
          return false;

        var bla = {
          "FakturaStavkaID": null,
          "FakturaID": null,
          "ArtiklID": null,
          "Naziv": null,
          "RedniBroj": this.stavke.length + 1,
          "Kolicina": 1,
          "Cijena": null,
          "Marža": null,
          "CijenaMarža": 0,
          "RabatPostotak": 0,
          "RabatIznos": 0,
          "PDVPostotak": 0,
          "Iznos": 0,
          "DodatniOpis": null,
          "MjestoTroškaID": null,
          "artiklnaziv": null,
          "isEdit": true,
          "isNew": true
        };

        const newIndex = this.stavke.length
        bla.index = newIndex

        this.$set(this.stavke, newIndex, bla)

        this.editCellHandler(this.stavke[newIndex], "artiklnaziv")
        this.artiklPickerHandler(this.stavke[newIndex])
      },
      cancelAddNew() { //nije najsretnije nazvano al šta sad - ovo je cancel od artiklmodal 
        if (this.currenteditrow_original.isNew) { //ako je iz add new, briši novu stavku
          this.cancelEditRowHandler(this.stavke.length-1)
          this.stavke.pop()
        } else { // ako je iz edita, samo undo promjena 
          this.cancelEditRowHandler(this.currenteditrow.index)
        }
      },
      cancelEditRowHandler(index) {          
        this.$set(this.stavke, index, this.currenteditrow_original)
        this.stavke[index].isEdit = false

        this.currenteditrow = null
        this.currenteditrow_original = null

      },      
      editCommitRowHandler(index) {    
        //this.mystavke = this.stavke; //kopiramo nazad da trigeramo refresh, bez ovoga se mystavke desinkronizira

        if ('previewKolicina' in this.currenteditrow) {
          this.stavke[index].Cijena = this.currenteditrow.previewCijena;
          this.stavke[index].CijenaMarža = this.currenteditrow.previewCijenaMarža;
          this.stavke[index].Marža = this.currenteditrow.previewMarža;
          this.stavke[index].Kolicina = this.currenteditrow.previewKolicina;
          this.stavke[index].RabatPostotak = this.currenteditrow.previewRabatPostotak;
          this.stavke[index].RabatIznos = this.currenteditrow.previewRabatIznos;
          this.stavke[index].Iznos = this.currenteditrow.previewIznos;
        }
 
        this.stavke[index].isEdit = !this.stavke[index].isEdit;      
        this.stavke[index].isNew = false;
        this.currenteditrow = null;
        this.currenteditrow_original = null;
        
        this.doRecap();
      },
      editCellHandler(data, field) {        
        if (!field.isEditable)
          return false; 

        var doInit = this.currenteditrow == null; 
      
        if (!doInit)
          doInit = this.currenteditrow.index != data.index

        if (doInit) {         
          if (this.currenteditrow_original) { //cancel prošlog edita
            //this.stavke[this.currenteditrow.index] = this.currenteditrow;
            this.$set(this.stavke, this.currenteditrow.index, this.currenteditrow_original);
            //console.log(this.stavke[index]);
            //this.$refs.selectableTable.refresh(); //wtf bez ovoga se neće tabela apdejtat, vjerojatno zbog toga što replejsam cijeli red u objektu
          }

          this.currenteditrow = Object.assign({}, this.stavke[data.index]);
          this.currenteditrow.index = data.index;

          this.currenteditrow_original = Object.assign({}, this.currenteditrow);

          this.stavke = this.stavke.map(item => ({...item, isEdit: false}));
          this.stavke[data.index].isEdit = true;
        }
      },    
      deleteStavka(data) {
        if (data.item.FakturaStavkaID != null) {
          this.stavkeDeleted.push(data.item.FakturaStavkaID)
        }        
        
        this.stavke.splice(data.index, 1);
        this.fixStavkeRbr();
        this.doRecap();
      }, 
      doStavkaCalc(newVal, fieldKey) {    
        // console.log(newVal);
        // console.log(fieldKey);

        if (typeof newVal === 'undefined')
          return; 

        if (typeof newVal === 'string' && this.fields.find(x => x.key == fieldKey).type == 'number')
          newVal = parseFloat(newVal.replace(",", "."));

        if (!('previewKolicina' in this.currenteditrow)) {
          this.currenteditrow['previewKolicina'] = this.currenteditrow.Kolicina;
          this.currenteditrow['previewIznos'] = this.currenteditrow.Iznos;
          this.currenteditrow['previewCijena'] = this.currenteditrow.Cijena;
          this.currenteditrow['previewCijenaMarža'] = this.currenteditrow.CijenaMarža;
          this.currenteditrow['previewMarža'] = this.currenteditrow.Marža;
          this.currenteditrow['previewRabatPostotak'] = this.currenteditrow.RabatPostotak;
        }

        this.currenteditrow['preview' + fieldKey] = parseFloat(newVal);

        if (this.currenteditrow.previewMarža) {
          this.currenteditrow.previewCijenaMarža = this.currenteditrow.previewCijena * (1 + (this.currenteditrow.previewMarža / 100))
        } else {
          this.currenteditrow.previewCijenaMarža = 0;
        }

        var cijena = this.currenteditrow.previewCijenaMarža == 0 ? this.currenteditrow.previewCijena : this.currenteditrow.previewCijenaMarža;
        //console.log(cijena);
        if (this.currenteditrow.previewRabatPostotak != 0) {
          this.currenteditrow.previewRabatIznos = (cijena * this.currenteditrow.previewKolicina) * (this.currenteditrow.previewRabatPostotak / 100)
        } else {
          this.currenteditrow.previewRabatIznos = 0;
          this.currenteditrow.previewRabatPostotak = 0;
        }
                        
        this.currenteditrow.previewIznos = cijena * this.currenteditrow.previewKolicina - this.currenteditrow.previewRabatIznos;        
      },
      artiklPickerHandler(data) {
        this.editCellHandler(data, this.fields.find(x => x.key == 'artiklnaziv'));

        this.$refs.artiklPicker.show();
      },
      updateArtiklCallbackObrada: function(item) {
        
        if (this.currenteditrow['previewKolicina'] == 0)
          this.doStavkaCalc(1, 'Kolicina');
        
        this.currenteditrow['Cijena'] = item.Cijena;
        this.currenteditrow['PDVPostotak'] = item.PDVPostotak;
        this.currenteditrow['Marža'] = item.MaržaPostotak * 100;
        this.doStavkaCalc(item.Cijena, 'Cijena');

        this.currenteditrow['artiklnaziv'] = item.Naziv;
        this.currenteditrow['artiklnaziv_orig'] = item.Naziv;
        this.currenteditrow['ArtiklID'] = item.ArtiklID;
        this.currenteditrow['DodatniOpis'] = item.Opis;

        this.currenteditrow.isEdit = true; 
        this.$set(this.stavke, this.currenteditrow.index, this.currenteditrow);
        // this.stavke[this.currenteditrow.index] = this.currenteditrow;
        // this.$refs.selectableTable.refresh(); //wtf bez ovoga se neće tabela apdejtat, vjerojatno zbog toga što replejsam cijeli red u objektu
        //this.$refs.selectableTable.items[this.currenteditrow.index] = this.currenteditrow;
      },
      updateNazivArtikla(data) {    
        this.editCellHandler(data, this.fields.find(x => x.key == 'artiklnaziv'));

        this.$refs['artiklNazivModal'].naziv = data.item.artiklnaziv_orig;
        this.$refs['artiklNazivModal'].newnaziv = data.item.artiklnaziv;
        this.$refs['artiklNazivModal'].dodatniopis = data.item.DodatniOpis;

        this.$refs['artiklNazivModal'].show();

        // var newnaziv = prompt("Unesite novi naziv artikla\n" + data.item.artiklnaziv_orig);
        // if (!newnaziv) {
        //   this.cancelEditRowHandler(this.currenteditrow.index);
        //   return; 
        // }
      },      
      updateArtiklNazivCallback(newnaziv, dodatniopis) {

        if (newnaziv == "") {
          this.currenteditrow['artiklnaziv'] = this.currenteditrow['artiklnaziv_orig'];
          this.currenteditrow['Naziv'] = this.currenteditrow['artiklnaziv'];
        } else {
          this.currenteditrow['artiklnaziv'] = newnaziv;
          this.currenteditrow['Naziv'] = newnaziv;
        }

        if (dodatniopis == "")
          dodatniopis = null

        this.currenteditrow['DodatniOpis'] = dodatniopis;

        this.currenteditrow.isEdit = true; 
        this.$set(this.stavke, this.currenteditrow.index, this.currenteditrow);
      },
      updateArtiklCallback(item) {
        var t = this; 
        if (item.CijenaValuta > 0) {
          tecaj.get(item.ValutaID).then(response => 
            item.Cijena = response.data.Tecaj * item.CijenaValuta            
          ).then(
            () => t.updateArtiklCallbackObrada(item)
          )
        } else {
          this.updateArtiklCallbackObrada(item);
        }
      },
      doRecap() {
        var iznos = 0;
        var iznosrabat = 0;
        var iznospdv = 0;
        var ukupno = 0;

        this.stavke.forEach((s) => {
          iznos += s.Iznos;
          iznosrabat += s.RabatIznos;
          iznospdv += s.Iznos * s.PDVPostotak;    
        });

        ukupno = iznos + iznospdv; 
        this.$parent.updateTotal(iznos, iznosrabat, iznospdv, ukupno);        
      },
      numberFormatter: function(value) {
        return value; 
        //return value.toFixed(2).toString().replace(".", ",");
      },
      currencyFormatter: function(value) {
        return utils.currencyFormatter(value, this.$parent.item.DatumDokumenta);
      },

      rabatCurrencyFormatter: function(value) {
        if (isNaN(value))
          return ""; 

        if (value == 0)
          return "";

        return utils.currencyFormatter(value, this.$parent.item.DatumDokumenta);
        
        // var formatter = new Intl.NumberFormat('hr-HR', {
        //   style: 'currency',
        //   currency: 'HRK',
        //   minimumFractionDigits: 2
        // });

        // return (formatter.format(value)).replace("HRK", "");
      },

      rabatFormatter: function(value) {
        if (value == 0)
          return "-";
        else
          return value + " %";
      },

      maržaFormatter: function(value) {
        if (value == 0 || value == null)
          return "";
        else
          return "+ " + value + " %";
      },
      fixStavkeRbr() {
        var i = 1;
        this.stavke.forEach((value, index) => {
          this.stavke[index].RedniBroj = i;
          i++;
        });
      },
    },
    data() {
        return {
          stavkeDeleted: [],
          isHovered: false,
          tecaj: null,
          stavke: [],
          columnName: 'RedniBroj',
          descendingSort: false,
          perPage: 10,
          currentPage: 1,
          fields: [
            { key: 'RedniBroj', 
              label: 'Rbr',
              sortable: true, 
              isEditable: false, 
              showPreview:false
            },
            { key: 'artiklnaziv', 
              label: 'Naziv', 
              sortable: true, 
              isEditable: true, 
              showPreview:false
            },
            { key: 'Kolicina', 
              label: 'Količina', 
              formatter: 'numberFormatter', 
              class: 'small-tablecol', 
              type: 'number', 
              isEditable: true, 
              showPreview: false
            },
            { key: 'Cijena', 
              label: 'Cijena / Marža', 
              formatter: 'currencyFormatter', 
              class: 'small-tablecol', 
              type: 'number', 
              sortable: true, 
              isEditable: true, 
              showPreview: false
            },
            { key: 'RabatPostotak', 
              label: 'Rabat', 
              formatter: 'rabatFormatter', 
              class: 'small-tablecol', 
              type: 'number', 
              isEditable: true, 
              showPreview: true},
            { key: 'Iznos', 
              label: 'Iznos', 
              formatter: 'currencyFormatter', 
              class: 'small-tablecol', 
              isEditable: false, 
              showPreview: true
            },
            {key: 'edit', label: ' ', type: 'text', showPreview:false, isEditable:false},
            {key: 'Marža', type:'number',  thClass: 'd-none', tdClass: 'd-none' }
          ],
          selected: {a: 1},
          currenteditrow: null,
          currenteditrow_original: null
        }
    },
    components: {
      'ArtiklModal': ArtiklModal,
      'ArtiklNazivModal': ArtiklNazivModal
    }
})
</script>
