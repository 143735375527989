p  <template>    
  <b-table hover :items="items_filtered" :fields="fields" ref="dokumentprilogitems" 
    primary-key="ItemID"
    >

    <template #cell(Filename)="data">
      <div style="min-width: 40px;">
        <span @click="openFile(data.value)" style="cursor: pointer;">{{data.value}}</span>            
      </div>
    </template>  
    <template #cell(DokumentID)="data">
      <div style="min-width: 40px;">
        <span @click="deleteFile(data.value)" style="cursor: pointer;" title="briši dokument" class="fas fa-trash-alt"></span>            
      </div>
    </template>            

    </b-table>
</template>

<script>
//import datasource from "../_services/backend";

export default({
  name: 'DokumentPrilog',
  mounted() {        
    //this.backend = datasource.get(this.vrstadokumenta)
  },
  computed: {
    hasItems() {
      return this.items.count != 0;
    },
    items_filtered() {
      let bla = [];
      this.items.forEach(function(s){  
        if (!s.deleted)
          bla.push(s);
      });
      return bla;
    }
  },
  props: {
      vrstadokumenta: String,
      items: Array    
  },
  data() {
    return {
      //items: [],
      fields:[
        { key: 'Filename', 
          label: 'Dokumenti u privitku', 
        },
        { key: 'DokumentID', 
          label: '', 
        },
      ]        
    } 
  },
  methods: {
    openFile(data) {
      console.log(data)
      alert("todo")
    },
    deleteFile(data) {
      this.items.forEach(s => {
        if (s.DokumentID == data) {
          this.$set(this.items[this.items.indexOf(s)], 'deleted', true )
        }          
      })
    }
  }
})
</script>

<style>

</style>