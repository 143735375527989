<template>    
  <b-modal size="md" ref="uplataModal" hide-backdrop title="Djelomična uplata"
  @ok="handleOK" @cancel="handleCancel" >

  <div class="row">
      <div class="col-sm-6">
        <label for="PreostaliIznos">Preostali iznos</label>
        <b-form-input id="PreostaliIznos" readonly v-model="preostalo"></b-form-input>
      </div>      
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="IznosUplate">Iznos uplate</label>
        <b-form-input id="IznosUplate" ref="IznosUplate" @focus="$event.target.select()" autofocus v-model="uplaceno" ></b-form-input>
      </div>      
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label for="DatumUplate">Datum uplate</label>
        <date-picker id="DatumUplate" ref="DatumUplate" 
        :value="selected"
        @dp-change="selected = $event.date"
        :config="dpoptions"></date-picker>
      </div>      
    </div>

    <div class="row">
      <div class="col-sm-6">
      </div>      
    </div>

  </b-modal>
</template>

<script>
import moment from "moment";
import datasource from "../_services/backend";
const fakture = datasource.get("faktura");

export default({
  name: 'UplataModal',
  mounted() {        

  },
  computed: {
    
  },
  data() {    
    return {
      id: null,
      preostalo: 0,
      ukupno: 0,
      uplaceno: 0,
      selected: moment(),
      dpoptions: {
        useCurrent: true,
        format: 'DD.MM.YYYY.',
        locale: 'hr-HR',
      }
    } 
  },
  methods: {

    handleOK(bvModalEvent) {
      if (this.uplaceno == 0) {
        bvModalEvent.preventDefault();
        return false; 
      }

      if (typeof this.uplaceno === 'string')
        this.uplaceno = parseFloat(this.uplaceno.replace(",", "."));

      if (isNaN(this.uplaceno)) {
        bvModalEvent.preventDefault();
        return false; 
      }

      this.$emit('uplataSubmit', this.selected, this.uplaceno);
    },
    handleCancel() {
      
    },    
    show() {

      fakture.uplate(this.id)
        .then(response => {
          this.$set(this, 'preostalo', response.data.preostalo)
          this.$set(this, 'ukupno', response.data.ukupno)

          if (response.data.ukupno != response.data.preostalo)
            this.uplaceno = response.data.preostalo;
          
        });
              
      this.$refs['uplataModal'].show();
      
    },

  }
})
</script>

<style>

</style>