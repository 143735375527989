<template>    
  <b-modal size="sm" ok-only ref="dokumentUpload" hide-backdrop title="Upload priloga"
  @ok="handleOK" >
    <template #modal-ok>
      Dodaj
    </template>
    <div class="row">
      <div class="col-sm-12">
        <input type="file" id="file" ref="file" accept=".pdf" />
      </div>
    </div>


  </b-modal>
</template>

<script>
import datasource from "../_services/backend";

const upload = datasource.get("dokumentupload");

export default({
  name: 'DokumentUpload',
  mounted() {        

  },
  computed: {

  },
  prop: {
    vrstadokumenta: String
  },
  data() {
    return {
      file: null
    } 
  },
  methods: {

    handleOK() {
      let formData = new FormData();
      formData.append('file', this.$refs.file.files[0]);

      upload.post(formData).then(response => {
        this.$emit('uploadedFile', response.data);
      })    
      
    },  
    show() {
      this.$refs['dokumentUpload'].show();
    },

  }
})
</script>

<style>

</style>