<template>    
  <b-modal ref="emailModal" size="lg" hide-backdrop title="Slanje e-mailom"
  @ok="handleOK"  >
  <label for="partneremail">Primatelj(i)</label>
  <b-input name="partneremail" v-model="partner.Email" ref="partneremail"></b-input>
  <p>za slanje na više adresa odvojite ih sa ; (točkazarez)</p>

  <label for="subject">Subject</label>
  <b-input name="subject" v-model="subject"></b-input>
<p></p>
  <label for="body">Tekst e-maila</label>
  <b-textarea rows="10" no-resize name="body" v-model="body"></b-textarea>

    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="outline-secondary" @click="cancel()">
        Odustani
      </b-button>
      <b-button size="sm" variant="primary" @click="ok()">
        Pošalji
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import datasource from "../_services/backend";
const partner = datasource.get('partner') ;

export default({
  name: 'EmailModal',
  mounted() {        
    this.emailbackend = datasource.get(this.vrstadokumenta)
  },
  computed: {
    
  },
  props: {
      vrstadokumenta: String
  },
  data() {
    return {
      emailbackend: {},
      partner: {},
      subject: '',
      body: '',
    } 
  },
  methods: {
    handleOK() {
      var item = null;

      if (typeof this.$parent.item === 'undefined') 
        item = this.$parent.selected[0];
      else
        item = this.$parent.item;

      var id;
      switch (this.vrstadokumenta) {
        case 'faktura':
          id = item.FakturaID;
          break;
        case 'ponuda':
          id = item.PonudaID;
          break;
      }

      this.emailbackend.email(id, this.partner.Email, this.subject, this.body).then(response => {
        if (response.status == 200) {  
          this.$vToastify.success("Dokument uspješno poslan " + response.data, "OK")
        } else {
          this.$vToastify.error("Dokument nije poslan " + response.data, "Greška")
        }
      }).catch(() => {
        this.$vToastify.error("Dokument nije poslan (error 500)", "Greška")
      });
    },
    show() {
      var item = null;

      if (typeof this.$parent.item === 'undefined') 
        item = this.$parent.selected[0];
      else
        item = this.$parent.item;

      partner.getOne(item.PartnerID).then(response => this.partner = response.data).then(() => {

          switch (this.vrstadokumenta) {
            case 'faktura':
              this.subject = this.$store.state.details.tvrtka + ' - račun broj ' + item.Broj;
              this.body = `Pozdrav

Račun broj ${item.Broj} se nalazi u prilogu.

Svoje račune možete vidjeti i preuzeti na adresi http://insales-public.incito.hr/?token=${this.partner.PublicIDHex}

Ukoliko ne možete otvoriti prilog, potreban vam je Acrobat Reader. Možete ga preuzeti na adresi https://get.adobe.com/reader/`;
              break;
            case 'ponuda':
            this.subject = this.$store.state.details.tvrtka + ' - ponuda broj ' + item.Broj;
            this.body = `Pozdrav

Ponuda broj ${item.Broj} se nalazi u prilogu.

Ukoliko ne možete otvoriti prilog, potreban vam je Acrobat Reader. Možete ga preuzeti na adresi https://get.adobe.com/reader/`;
            break;
}
        
      });
      
      
      
      this.$refs['emailModal'].show();
      
    },
  }
})
</script>

<style>

</style>