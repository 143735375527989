import axios from 'axios' 

const resource = '/api/fakturastavka';

export default {
    getAll(fakturaid) {
        return axios.post(`${resource}/getall`, null, {params:{
            fakturaid: fakturaid
        }});
    },
    getOne(id) {
        return axios.get(`${resource}/?id=${id}`);
    },
    create(payload) {
        return axios.post(`${resource}/create`, payload);
    },
    update(payload, id) {
        return axios.put(`${resource}/${id}`, payload);
    },
    delete(id) {
        return axios.delete(`${resource}/${id}`)
    },
    
    // MANY OTHER ENDPOINT RELATED STUFFS
};