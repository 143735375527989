<template>
<div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{mode}} {{item.Broj}}</h1>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>

        <div class="row mb-2">
          <div class="col-sm-4">
            <b-button block variant="primary" @click="save">
              <span class="menuButton fas fa-save"></span>
              <span v-if="!isModal">&nbsp;Spremi</span>          
              <span v-if="isModal">&nbsp;Spremi i zatvori</span>          
            </b-button>            
          </div>
          <!-- <div class="col-sm-5 menuButtonOthers">
            <b-button variant="outline-secondary" @click="print" v-if="!isNew">
              <span class="menuButton fas fa-print"></span>
              <span>Ispis</span>          
            </b-button>      
            <b-button variant="outline-secondary" @click="email" v-if="!isNew">
              <span class="menuButton far fa-envelope"></span>
              <span>E-mail</span>          
            </b-button>                      
          </div>      -->
            
        </div>
      </div>
    </section>

    <section class="content">
        <div class="container-fluid">
  
      <div class="row mb-2">
        <div :class="modalclass">
          <div class="card mr-2 ml-2">
            <div class="card-body mr-2 ml-2">
              <div class="row mb-2">
                <label for="Naziv">Naziv</label>
                <b-form-input v-model="item.Naziv"></b-form-input>
              </div>
              <div class="row mb-2">
                <label for="Opis">Opis</label>
                <b-form-textarea v-model="item.Opis"></b-form-textarea>
              </div>              
              <div class="row mb-2">
                <label for="PartNumber">Part number</label>
                <b-form-input v-model="item.PartNumber"></b-form-input>
              </div>              
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2 mr-2">
                    <label for="Cijena">Cijena</label>
                    <b-form-input v-model="item.Cijena"></b-form-input>
                  </div>                    
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="CijenaValuta">Cijena valuta</label>
                    <b-form-input v-model="item.CijenaValuta"></b-form-input>
                  </div>                    
                </div>                
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2 mr-2">
                    <label for="Valuta">Valuta</label>
                    <b-form-select :options="valute" v-model="item.ValutaID"></b-form-select>
                  </div>                    
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="Porez">Jedinica mjere</label>
                    <b-form-input v-model="item.JM"></b-form-input>
                  </div>                    
                </div>                
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2 mr-2">
                    <label for="Porez">Porez</label>
                    <b-form-select :options="porezi" v-model="item.Porez"></b-form-select>
                  </div>                    
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="Marža">Marža %</label>
                    <b-form-input v-model="item.Marža"></b-form-input>
                  </div>                    
                </div>                
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2 mr-2">
                    <label for="Usluga">Usluga</label>
                    <b-form-checkbox class="ml-3" v-model="item.Usluga"></b-form-checkbox>
                  </div>                    
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="Predložak">Predložak</label>
                    <b-form-checkbox class="ml-3" v-model="item.Template"></b-form-checkbox>
                  </div>                    
                </div>                
              </div>  
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="Grupa">Grupa artikala</label>
                    <b-form-input v-model="item.Grupa"></b-form-input>
                  </div>                    
                </div>                                        
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">

        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

</div>    
</template>

<script>
import datasource from "../_services/backend"
const artikli = datasource.get("artikl")

export default({
  name: 'ArtiklModalEdit',
  mounted() {    
    if (!this.isModal) {
      if (this.$route.params.id == "-1") {
        this.isNew = true; 
      } else {
          artikli.getOne(this.$route.params.id)
            .then(response => {            
              this.$set(this, 'item', response.data) 
              if (this.item.Cijena == 0)
                this.item.Cijena = "";

              if (this.item.CijenaValuta == 0)
                this.item.CijenaValuta = "";

              if (this.item.Marža != 0)
                this.item.Marža = this.item.Marža * 100;

          });
      }
    } else {
      this.isNew = true;
    }
  },
  computed: {
    modalclass: {
      get: function() {
        if (this.isModal)
          return "col-sm-12"
        else
          return "col-sm-8"
      }
    },
    mode: {
      get: function() {
        if (this.isNew)
          return 'Novi artikl'    
        else 
          return 'Izmjena artikla'
      }
    },
    // isNew: {
    //   get: function(){
    //     return this.$route.params.id == "-1"
    //   }
    // }    
  },
  data() {
    return {      
      isNew: false,
      valute: [
        {value: '6f7c29ac-50ad-47e5-8ee5-d77897dad4d6', text:	'EUR'},
        {value: '226bdc66-546f-483c-8157-65a63dddb657', text:	'USD'},
        {value: 'b0723268-f5fe-4ba4-9d6e-d583ae7fc520', text:	'GBP'},        
      ],
      porezi: [
        {value: 0.25, text: '25%'},
        {value: 0.23, text: '23%'},
        {value: 0.22, text: '22%'},
        {value: 0.10, text: '10%'},
        {value: 0, text: '0%'},
      ],
      item: {
        Opis: '',
        PartNumber: '',
        Cijena: '',
        CijenaValuta: '',
        Porez: 0.25,
        JM: 'kom',
        Template: false, 
        Usluga: false,         
        MjestoTroškaID: null,
        Marža: 0,
        Naziv: ''
      },
    } 
  },
  props: { 
    isModal: Boolean
  },
  methods: {
    onRowSelected(selected) {
      this.item = selected[0]
    },
    handleOK() {
      this.$emit('artiklselected', this.item)
    },
    handleCancel() {
      this.$parent.cancelAddNew()
    },   
    show() {
      this.$refs['artiklPickerModal'].show()
    },
    save() {
//      console.log("bork");
      if (this.item.Naziv.length == 0) {
        this.$vToastify.warning("Naziv artikla je obavezan unos", "Poruka")     
        return false
      }

      if (this.item.Cijena == "" && this.item.CijenaValuta == "") {
        this.$vToastify.warning("Cijena ili Cijena u valuti su obavezan unos", "Poruka")     
        return false
      }

      if (parseFloat(this.item.Cijena) == 0 && parseFloat(this.item.CijenaValuta)  == 0) {
        this.$vToastify.warning("Cijena ili Cijena u valuti su obavezan unos", "Poruka")     
        return false
      }

      if (this.item.Cijena == "")
        this.item.Cijena = 0;
      if (this.item.CijenaValuta == "")
        this.item.CijenaValuta = 0;

      if (typeof this.item.CijenaValuta === 'string')
        this.item.CijenaValuta = parseFloat(this.item.CijenaValuta.replace(",", "."));

      if (typeof this.item.Marža === 'string')
        this.item.Marža = parseFloat(this.item.Marža.replace(",", "."));

      if (typeof this.item.Cijena === 'string')
        this.item.Cijena = parseFloat(this.item.Cijena.replace(",", "."));

      artikli.update({ 'item': this.item }).then(response => {
          if (response.status == 200) {
            this.$vToastify.success("Artikl uspješno spremljen", "OK")

            this.$emit('artiklsaved', response.data)
          } else {
            console.log(response)
          }            
        }).catch(error => {
          this.$vToastify.error("Greška prilikom spremanja artikla " + error.response.data, "Greška")
        });
      },    
  }
})
</script>

<style>

</style>