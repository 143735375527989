import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module'
import { account } from './account.module'
import { users } from './users.module'

import { userService } from '../_services';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    details: {},
    poslovnagodina: new Date().getFullYear(),
    sakrijplaceno: false,
    poslovnagodinanorefreshonnextchange: false,
  },
  modules: {
      alert,
      account,
      users
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('poslovnagodina') != null)
        state.poslovnagodina = localStorage.getItem('poslovnagodina');
      
        if (localStorage.getItem('sakrijplaceno') != null)
          state.sakrijplaceno = localStorage.getItem('sakrijplaceno');

        if (localStorage.getItem('user2') != null)
          userService.validateToken().then(data => { state.details = data });
    },
    updatePoslovnaGodina(state, godina) {
      state.poslovnagodina = godina;
      localStorage.setItem('poslovnagodina', godina);
    },
    updateSakrijPlaceno(state, value) {
      state.sakrijplaceno = value;
      localStorage.setItem('sakrijplaceno', value);
    }    
  },
  strict: false
})

// Automatically run the `init` action for every module,
// if one exists.
// for (const moduleName of Object.keys(modules)) {
//     if (modules[moduleName].actions && modules[moduleName].actions.init) {
//         store.dispatch(`${moduleName}/init`)
//     }
// }

export default store
