import axios from 'axios' 

const resource = '/api';

export default {
  post(file) {
    return axios.post(`${resource}/file`, 
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }
};