import axios from 'axios' 
import store from '../libs/store'

const resource = '/api/faktura';

export default {
  getAll() {
    return axios.post(`${resource}/getall`, null, {params:{
      poslovnagodina: store.state.poslovnagodina
    }});
  },
  getOne(id) {
    // var poslovnagodina = store.state.poslovnagodina;
    return axios.get(`${resource}/?id=${id}&poslovnagodina=${store.state.poslovnagodina}`);
  },
  create(payload) {
    return axios.post(`${resource}/create`, payload);
  },
  update(payload) {
    return axios.put(`${resource}/`, payload);
  },
  delete(id) {
    return axios.delete(`${resource}/?id=${id}`)
  },
  cancelPlaceno(id) {
    return axios.post(`${resource}/cancelPlaceno?id=${id}`)
  },  
  otpremnica(id) {
    return axios.post(`${resource}/otpremnica?id=${id}`)
  },
  markPlaceno(id, datum) {
    return axios.post(`${resource}/markPlaceno`, null, {params:{
      id: id,
      datum: datum
    }});
  },
  copy(ids) {
    ids = ids.join(",");
    return axios.post(`${resource}/copy`, null, {params:{
      ids
    }});
  },    
  storno(ids) {
    ids = ids.join(",");
    return axios.post(`${resource}/storno`, null, {params:{
      ids
    }});
  },       
  copyPonuda(ids) {
    ids = ids.join(",");
    return axios.post(`${resource}/copyPonuda`, null, {params:{
      ids
  }});
  },
  email(id, rcpt, subject, body) {
    return axios.post(`${resource}/email`, null, {params:{
      id: id,
      rcpt: rcpt,
      subject: subject,
      body: body
    }});
  },
  uplate(id) {
    return axios.get(`${resource}/uplate?id=${id}`);
  },
  uplata(id, datum, iznos) {
    return axios.post(`${resource}/uplata`, null, {
      params: {
        id: id,
        datum: datum,
        iznos: iznos
      }
    });    
  },  
  print(id) {
    return axios.get(`${resource}/print?id=${id}`, {responseType: 'blob'});
  },
  printOtpremnica(id) {
    return axios.get(`${resource}/printOtpremnica?id=${id}`, {responseType: 'blob'});
  },
  download(ids) {
    return axios.post(`${resource}/download`, ids, {responseType: 'blob'});
  },
};