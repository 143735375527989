import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'

import Dashboard from '@/components/Dashboard'
import FakturaBrowse from '@/components/FakturaBrowse'
import FakturaEdit from '@/components/FakturaEdit'
import FakturaRecurrentBrowse from '@/components/FakturaRecurrentBrowse'

import PonudaBrowse from '@/components/PonudaBrowse'
import PonudaEdit from '@/components/PonudaEdit'

import InvoiceBrowse from '@/components/InvoiceBrowse'
import InvoiceEdit from '@/components/InvoiceEdit'


import UlazniRacunBrowse from '@/components/UlazniRacunBrowse'
import UlazniRacunEdit from '@/components/UlazniRacunEdit'

import ArtiklBrowse from '@/components/ArtiklBrowse'
import ArtiklEdit from '@/components/ArtiklEdit'
import ArtiklKartica from '@/components/ArtiklKartica'

import PartnerBrowse from '@/components/PartnerBrowse'
import PartnerEdit from '@/components/PartnerEdit'

import PublicLayout from '@/layouts/Public.vue'
import HomeLayout from '@/layouts/Home.vue'

import * as utils from "../helpers/index";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'activebork',
  routes: [
    // {
    //   path: '/',
    //   component: PublicLayout,
    //   children: [
    //     {
    //       path: '',
    //       component: HelloWorld
    //     }
    //   ]
    // },
    {
      path: '/login',
      name: 'login',
      component: PublicLayout,
      children: [
        {
          path: '/login', name:'', component: Login
        }
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: HomeLayout,
      children: [
        {
          path: '/dashboard', name:'', component: Dashboard
        }
      ]
    },
    {
      path: '/faktura',
      name: 'fakturabrowse',
      component: HomeLayout,
      children: [
        {
          path: '/faktura', name: 'faktura.browse', component: FakturaBrowse
        },
        { 
          path: '/faktura/edit/', name: 'faktura.new', component: FakturaEdit
        },
        { 
          path: '/faktura/edit/:id', name: 'faktura.edit', component: FakturaEdit
        }
      ]
    },
    {
      path: '/fakturarecurrent',
      name: 'fakturarecurrentbrowse',
      component: HomeLayout,
      children: [
        {
          path: '/fakturarecurrent', name: 'fakturarecurrent.browse', component: FakturaRecurrentBrowse
        },
        // { 
        //   path: '/fakturarecurrent/edit/', name: 'fakturarecurrent.new', component: FakturaEdit
        // },
        // { 
        //   path: '/fakturarecurrent/edit/:id', name: 'fakturarecurrent.edit', component: FakturaEdit
        // }
      ]
    },    
    {
      path: '/ponuda',
      name: 'ponudabrowse',
      component: HomeLayout,
      children: [
        {
          path: '/ponuda', name: 'ponuda.browse', component: PonudaBrowse
        },
        { 
          path: '/ponuda/edit/', name: 'ponuda.new', component: PonudaEdit
        },
        { 
          path: '/ponuda/edit/:id', name: 'ponuda.edit', component: PonudaEdit
        }
      ]
    },    
    {
      path: '/ulazniracun',
      name: 'ulazniracunbrowse',
      component: HomeLayout,
      children: [
        {
          path: '/ulazniracun', name: 'ulazniracun.browse', component: UlazniRacunBrowse
        },
        { 
          path: '/ulazniracun/', name: 'ulazniracun.new', component: UlazniRacunEdit
        },
        { 
          path: '/ulazniracun/edit/:id', name: 'ulazniracun.edit', component: UlazniRacunEdit
        }
      ]
    },       
    {
      path: '/invoice',
      name: 'invoicebrowse',
      component: HomeLayout,
      children: [
        {
          path: '/invoice', name: 'invoice.browse', component: InvoiceBrowse
        },
        { 
          path: '/invoice/edit/', name: 'invoice.new', component: InvoiceEdit
        },
        { 
          path: '/invoice/edit/:id', name: 'invoice.edit', component: InvoiceEdit
        }
      ]
    },    
    {
      path: '/partner',
      name: 'partnerbrowse',
      component: HomeLayout,
      children: [
        {
          path: '/partner', name: 'partner.browse', component: PartnerBrowse
        },
        { 
          path: '/partner/edit/', name: 'partner.new', component: PartnerEdit
        },
        { 
          path: '/partner/edit/:id', name: 'partner.edit', component: PartnerEdit
        }
      ]
    },    
    {
      path: '/artikl',
      name: 'artikbrowse',
      component: HomeLayout,
      children: [
        {
          path: '/artikl', name: 'artikl.browse', component: ArtiklBrowse
        },
        { 
          path: '/artikl/edit/', name: 'artikl.new', component: ArtiklEdit
        },
        { 
          path: '/artikl/edit/:id', name: 'artikl.edit', component: ArtiklEdit
        },
        { 
          path: '/artikl/kartica/:id', name: 'artikl.kartica', component: ArtiklKartica
        }
      ]
    },    


    // otherwise redirect to home
    { path: '*', redirect: '/dashboard' }
  ]
})

router.beforeEach((to, from, next) => {

  //TODO za neku budućnost, alert ako ima ne spremljenih promjena 
  //change detection is a bitch (╯°□°）╯︵ ┻━┻
  // if (from.name != null)
  //   if (sessionStorage.getItem(from.name.split(".")[0]) != null) {
  //     if (confirm("Promljene nisu spremljene, potvrdite izlazak bez spremanja promjena")) {
  //       sessionStorage.removeItem(from.name.split(".")[0])
  //       console.log(sessionStorage.getItem(from.name.split(".")[0]))
  //     } else {
  //       return false;
  //     }            
  //   }
  
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user2')

  if (to.path === '/login' || to.name === 'login'){
    next()
  }
  else if (authRequired && !loggedIn) {
    router.push("/login");
  } else if (loggedIn) {
    next()
  } else {
    next({ name: 'login' })
  }

})

router.afterEach((to, from) => {
  if (to.path != from.path)
    utils.EventBus.$emit('triggersearch')
  
  if (to.name.includes(".browse"))
    utils.EventBus.$emit('showsearch')
  else
    utils.EventBus.$emit('hidesearch')
  
  if ((to.name == 'faktura.browse') || (to.name == 'ponuda.browse'))
    utils.EventBus.$emit('showSakrijPlaceno')
  else
    utils.EventBus.$emit('hideSakrijPlaceno')
  
})

export default router
