import axios from 'axios' 

const resource = '/api';

export default {
  get(dokumentID) {
    return axios.post(`${resource}/log`, null, {
      params: {
        DokumentID: dokumentID
      }
    });
  }
};