<template>    
  <b-modal ref="artiklNazivModal" size="lg" hide-backdrop title="Izmjena naziva artikla"
  @ok="handleOK"  >

  <label for="naziv">Naziv</label>
  <b-input name="naziv" v-model="naziv" ref="naziv"></b-input>

  <label for="newnaziv">Novi naziv</label>
  <b-input name="newnaziv" v-model="newnaziv" ref="newnaziv"></b-input>

  <label for="dodatniopis">Dodatni opis stavke</label>
  <b-textarea name="dodatniopis" v-model="dodatniopis" ref="dodatniopis"></b-textarea>

    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="outline-secondary" @click="cancel()">
        Odustani
      </b-button>
      <b-button size="sm" variant="primary" @click="ok()">
        Prihvati
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default({
  name: 'ArtiklNazivModal',
  mounted() {        
    
  },
  computed: {
    
  },
  props: {

  },
  data() {
    return {
      naziv: this.naziv,
      newnaziv: this.newnaziv,
      dodatniopis: this.dodatniopis
    } 
  },
  methods: {
    handleOK() {
      this.$emit('nazivchange', this.newnaziv, this.dodatniopis);
    },
    show() {
      this.$refs['artiklNazivModal'].show();      
    },
  }
})
</script>

<style>

</style>