<template>
<div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{mode}} {{item.Broj}}</h1>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>

        <div class="row mb-2">
          <div class="col-sm-2">
            <b-button block variant="primary" @click="save">
              <span class="menuButton fas fa-save"></span>
              <span>&nbsp;Spremi</span>          
            </b-button>            
          </div>
          <!-- <div class="col-sm-5 menuButtonOthers">
            <b-button variant="outline-secondary" @click="print" v-if="!isNew">
              <span class="menuButton fas fa-print"></span>
              <span>Ispis</span>          
            </b-button>      
            <b-button variant="outline-secondary" @click="email" v-if="!isNew">
              <span class="menuButton far fa-envelope"></span>
              <span>E-mail</span>          
            </b-button>         
            <b-button variant="outline-secondary" @click="showLog" v-if="!isNew">
              <span class="fas fa-history"></span>
              <span title="Povijest dokumenta (log)">&nbsp;Log</span>          
            </b-button>                           
          </div>      -->
          <div class="col-sm-2">
        
          </div>                
        </div>

        <div class="row mb-2">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <label for="partner">Partner</label>
                  <vue-typeahead-bootstrap id="partner"
                  placeholder="upišite dio naziva partnera"
                  v-model="partnerquery"
                  :data="partneri"
                  @input="updatePartnerItems"
                  :serializer="item => item.Naziv + ' ' + item.Mjesto"
                  @hit="displayPartner = $event"
              />
              <!-- <br>
              {{ partner.Adresa }} {{ partner.Email }} -->
              </div>
            </div>
          </div>
          <div class="col-sm-4">

          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="DatumDokumenta">Datum dokumenta</label>
                    <date-picker class="col-sm-5 col-md-4" id="DatumDokumenta" ref="DatumDokumenta"
                    :value="item.DatumDokumenta" 
                    @dp-change="updateUlazniRacunHeaderItem('DatumDokumenta', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="ValutaDokumenta">Dospijeće</label>
                    <date-picker class="col-sm-5 col-md-4" id="ValutaDokumenta"
                    :value="item.DatumValute" 
                    @dp-change="updateUlazniRacunHeaderItem('DatumValute', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>                  
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="Veza">Broj</label>
                    <b-form-input v-model="item.URABroj"></b-form-input>
                  </div>
                </div>                
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="PozivNaBroj">Poziv na broj</label><br>
                    <div class="input-group">
                      <div class="input-group-prepend" style="width: 60px;">
                        <b-form-input v-model="item.PozivNaBrojModel" size="3" style="width: 45px;" ></b-form-input>
                      </div>                    
                      <b-form-input v-model="item.PozivNaBroj"  class="form-control"  ></b-form-input>                      
                    </div>
                  </div>
                </div>                                
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="Veza">Napomena</label>
                    <b-form-textarea rows="3" max-rows="3" v-model="item.Napomena"></b-form-textarea>
                  </div>
                </div>                
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="Plaćeno">Plaćeno</label>
                    <date-picker class="col-sm-5 col-md-4" id="Plaćeno" ref="Plaćeno"
                    :value="item.DatumPlaceno" 
                    @dp-change="updateUlazniRacunHeaderItem('DatumPlaceno', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-5" for="ValutaDokumenta">PDV razdoblje</label>
                    <b-form-select v-model="item.PDVRazdobljeID" class="col-sm-5 col-md-7" :options="pdvrazdoblja"></b-form-select>
                  </div>                  
                </div>              

              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">            
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznos">Iznos</label>
                    <b-form-input class="col-sm-7 text-right" id="iznos" @change="updateTotal" v-model="item.Iznos" @focus="$event.target.select()"></b-form-input>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznosrabat">Iznos rabata</label>
                    <b-form-input class="col-sm-7 text-right" id="iznosrabat" @change="updateTotal" v-model="item.IznosRabat" @focus="$event.target.select()"></b-form-input>
                  </div>
                </div>
               <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznosporez">Iznos poreza</label>
                    <b-form-input class="col-sm-7 text-right" id="iznosporez" @change="updateTotal" v-model="item.IznosPDV" @focus="$event.target.select()"></b-form-input>
                  </div>
                </div>    
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="ukupno">Ukupno</label>
                    <b-form-input class="col-sm-7 text-right" id="ukupno" v-model="item.Ukupno" @focus="$event.target.select()"></b-form-input>
                  </div>
                </div>                              
              </div>
            </div>
          </div>
        </div>


      </div><!-- /.container-fluid -->
    </section>

</div>
    
</template>

<script>
import datasource from "../_services/backend";
import * as utils from "../helpers/index";

import { debounce } from 'lodash'

import moment from "moment-timezone";
import axios from 'axios' 
import store from "../libs/store";

const ulazniracuni = datasource.get("ulazniracun");
const partneri = datasource.get("partner");

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default({
    name: 'UlazniRacunEdit',
      mounted() {
        this.refreshPDVrazdoblja()

        var tt = this;  //unutar handlera this ne postoji pa ga moraš spremiti vani
        unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
          if (mutation.type == 'updatePoslovnaGodina') {
            if (!this.isNew) {
              this.$router.push("/ulazniracun");
            }

            tt.refreshPDVrazdoblja()
          }        
        });    
        moment.tz.setDefault("Europe/Zagreb")

        if (this.$route.params.id == "-1") {
          this.$set(this.item, 'DatumDokumenta', moment().local())
          this.$set(this.item, 'DatumValute',  moment().add(7, 'days').local())

          this.$set(this.item, 'Veza', '')
          this.$set(this.item, 'TekstDodatno', '')

          this.$set(this.item, 'Iznos', '0')
          this.$set(this.item, 'IznosPDV', '0')
          this.$set(this.item, 'IznosRabat', '0')
          this.$set(this.item, 'Ukupno', '0')
          this.$set(this.item, 'PDVRazdobljeID', '00000000-0000-0000-0000-000000000000')

        } else {
            ulazniracuni.getOne(this.$route.params.id)
            .then(response => {
              this.item = response.data['ulazniracun']
              this.item.DatumDokumenta = moment(this.item.DatumDokumenta).local()
              this.item.DatumValute = moment(this.item.DatumValute).local()
              this.item.DatumPlaceno = moment(this.item.DatumPlaceno).local()

              this.partner = response.data['partner']

              this.partner.toString = function() {
                return this.Naziv + " " + this.Mjesto
              }
              this.partnerquery = this.partner.toString()

            });
        }

    },
    beforeRouteLeave(to,from,next) {
      unsubscribe();
      next();
    },    
    computed: {
      displayPartner: {
        get: function() {
          return this.partner;
        },
        set: function(value) {
          this.partner = value;
          this.$set(this.item, "DatumValute", moment().local().add(this.partner.Valuta, 'days'));
        }
      },
      isNew: {
        get: function(){
          return this.$route.params.id == "-1"
        }
      },
      mode: {
        get: function() {
          if (this.isNew)
            return 'Novi ulazni račun'    
          else 
            return 'Izmjena ulaznog računa';
        }
      }
    },
    data() {
        return {
          pdvrazdoblja: [],
          partnerquery: '',
          partneri: [],
          item: {
            Broj: '',
            PozivNaBrojModel: '',
            PozivNaBroj: '',
            Napomena: '',
          },
          partner: {},
          query: '',
          dpoptions: {
            useCurrent:false,
            format: 'DD.MM.YYYY.',
            locale: 'hr-HR',

          }
        }
    },
    methods: {
      refreshPDVrazdoblja() {
        axios.get(`/api/pdvrazdoblja/?poslovnagodina=${store.state.poslovnagodina}`).then(response => {
          var pdvr = [];
          response.data.forEach((s) => {
            pdvr.push({text: s.Naziv, value: s.PDVRazdobljeID, datumdo: s.DatumDO })
          })          

          this.$set(this, 'pdvrazdoblja', pdvr)
        })
      },
      // print() {
      //   // ulazniracuni.print(this.item.UlazniRacunID).then(response => {   
      //   //   var fileURL = URL.createObjectURL(response.data);
      //   //   window.open(fileURL, "_blank");
      //   // });
      //   window.open("/api/ulazniracun/print?id=" + this.item.UlazniRacunID + "&token=" + JSON.parse(localStorage.getItem('user2')).token, "_blank");
      // },
      // email() {
      //   this.$refs['emailModal'].show();
      //   setTimeout(() => this.$refs['emailModal'].$refs['partneremail'].focus(), 500);
      // },
      // showLog() {
      //   this.$refs['dokumentLogModal'].show();
      // },  
      updateTotal() {
        this.item.Iznos = this.item.Iznos.toString().replace(",", ".");
        this.item.IznosRabat = this.item.IznosRabat.toString().replace(",", ".");
        this.item.IznosPDV = this.item.IznosPDV.toString().replace(",", ".");

        this.item.Ukupno = (parseFloat(this.item.Iznos) - parseFloat(this.item.IznosRabat) + parseFloat(this.item.IznosPDV)).toFixed(2)
      },   
      save() {
        // if (this.$refs.stavkeComponent.stavke.length == 0) {
        //   this.$vToastify.warning("Dokument mora imati minimalno jednu stavku", "Poruka");       
        //   return false; 
        // }

        this.item.PartnerID = this.displayPartner.PartnerID;

        ulazniracuni.update({ 
          ulazniracun: this.item,
          PDVRazdobljeID: this.item.PDVRazdobljeID
          }).then(response => {
            if (response.status == 200) {
              this.$vToastify.success("Dokument uspješno spremljen", "OK")

              if (this.isNew) {
                this.$set(this, 'item', response.data);
                this.$router.replace({path: '/ulazniracun', name: 'ulazniracun.edit', params: { id: response.data.URAID}});

                ulazniracuni.getOne(this.$route.params.id)
                .then(response => {
                  this.item = response.data['ulazniracun']
                  this.item.DatumDokumenta = moment(this.item.DatumDokumenta).local()
                  this.item.DatumValute = moment(this.item.DatumValute).local()
                  this.item.DatumPlaceno = moment(this.item.DatumPlaceno).local()

                  this.partner = response.data['partner']

                  this.partner.toString = function() {
                    return this.Naziv + " " + this.Mjesto
                  }
                  this.partnerquery = this.partner.toString()
                });  

              }
            } else {
              console.log(response);
            }            
          }).catch(error => {
            this.$vToastify.error("Greška prilikom spremanja dokumenta " + error.response.data, "Greška");       
          });
      },
      // updateTotal(iznos, iznosrabat, iznospdv, ukupno) {
      //   this.$set(this.item, "Iznos", iznos);
      //   this.item.Osnovica = iznos;
      //   this.item.IznosRabat = iznosrabat;
      //   this.item.IznosPDV = iznospdv;
      //   this.item.Ukupno = ukupno;
      // },
      updateUlazniRacunHeaderItem(key, value) {
        this.item[key] = value;
      },
      updatePartnerItems: debounce(function() {      
        if (Object.keys(this.partner).length === 0 || !this.partnerquery.trim().toLowerCase().includes(this.partner.Naziv.toLowerCase()))
          partneri.get(this.partnerquery).then(response => this.partneri = response.data);        
      }, 500),
      currencyFormatter: function(value) {
        return utils.currencyFormatter(value);
      },
      currencyFormatter2: function(value) {
        return utils.currencyFormatter2(value);
      }      
    }
})
</script>
<style scoped>
.menuButton {
  margin-right: .2rem;
}
.menuButtonOthers button {
  margin-right: .5rem;
}
</style>