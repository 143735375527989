<template>
    <router-view />
</template>

<script>

  export default {
    name: 'PublicLayout',
    data () {
    return {
      msg: 'public layout'
    }
  }
  }
</script>

<style>
</style>
