<template>    
  <b-modal ref="artiklPickerModal" size="lg" hide-backdrop :no-close-on-backdrop="true" :no-close-on-esc="true" title="Odabir artikla"
  @ok="handleOK" @cancel="handleCancel" >
    <b-tabs card content-class="mt-6" @input="onTabChanged">
      <!-- <span v-text="item"></span> -->
      <b-tab title="Brzi odabir" active>
          <vue-typeahead-bootstrap
          placeholder="upišite dio naziva artikla ili grupe artikala"
            v-model="query"
            :data="artikli"
            @input="updateItems"
            :serializer="item => item.Sifra + ' ' + item.Naziv + ' ' + (item.Grupa != null ? '(' + item.Grupa + ') ' : '') + (item.CijenaValuta == 0 ? item.Cijena + ' €' : item.CijenaValuta + ' ' + item.ValutaNaziv)"
            @hit="item = $event"
          />
      </b-tab>
      <b-tab title="Pregled svih">
          <div>
            <b-form-select v-model="selectedgrupa" :options="$store.state.details.grupeartikala" @change="refreshArtiklBrowser"></b-form-select>
          </div>  
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="artiklmodalsviartiklitable"
          ></b-pagination>
          <b-table hover sortable selectable :items="paginatedItems" :fields="fields" ref="artiklmodalsviartiklitable" 
            select-mode="single"
            primary-key="ArtiklID"
            @row-selected="onRowSelected"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="columnName"
            :sort-desc.sync="descendingSort"
            :no-sort-reset="true"
            >
            </b-table>
      </b-tab>
      <b-tab title="Unos novog">
        <ArtiklModalEdit ref="artikleditComponent" :isModal="true" @artiklsaved="onAddNew"></ArtiklModalEdit>
      </b-tab>
    </b-tabs>  
  </b-modal>
</template>

<script>
import datasource from "../_services/backend";
import * as utils from "../helpers/index";
import {debounce} from 'lodash';
import store from "../libs/store";

import ArtiklModalEdit from "./ArtiklModalEdit.vue"

const artikli = datasource.get("artikl");

export default({
  name: 'ArtiklModal',
  mounted() {        
    
  },
  computed: {

  },
  data() {
    return {
      query: '',
      item: {},
      artikli: [],
      columnName: 'Sifra',
      descendingSort: false,      
      perPage: 10,
      currentPage: 1,   
      totalRows: -1,   
      selectedgrupa: 'sve grupe artikala',
      currentPaginationCtx: null,
      currentTabVisible: 0,
      fields:[
        { key: 'Sifra', 
          label: 'Šifra', 
          sortable: true,
          thClass: 'sortable',
        },
        { key: 'Naziv', 
          label: 'Naziv', 
          sortable: true,
          thClass: 'sortable',
        },        
        { key: 'Cijena', 
          label: 'Cijena', 
          formatter:  (value, key, item) => { 
            if (item.CijenaValuta == 0) {
              return utils.currencyFormatter(value, store.state.poslovnagodina + "-12-31")
            } else {
              return utils.currencyFormatter2(item.CijenaValuta, item.ValutaNaziv) 
            }
          }, 
          sortable: true,
          thClass: 'sortable',
        },
        { key: 'Grupa', 
          label: 'Grupa', 
          sortable: true,
          thClass: 'sortable',
        },        
      ]
    } 
  },
  methods: {
    onTabChanged(tabIndex) {
      this.currentTabVisible = tabIndex;
    },
    onRowSelected(selected) {
      this.item = selected[0];
    },
    refreshArtiklBrowser() {
      this.$refs.artiklmodalsviartiklitable.refresh()
    },
    paginatedItems(ctx) {  
      const promise = artikli.getAllPaginated(ctx.currentPage, ctx.perPage, ctx.sortBy, ctx.sortDesc, this.selectedgrupa);

      var t = this;
      // Must return a promise that resolves to an array of items
      return promise.then(data => {   
        t.totalRows = data.headers['x-total-rows'];
        // Must return an array of items or an empty array if an error occurred
        return data.data || []
      })
    },
    handleOK(bvModalEvent) {
      if (this.currentTabVisible == 2) {
        alert("Za spremanje artikla i odabir kliknite Spremi i zatvori.");
        bvModalEvent.preventDefault()
      } else {
        if (Object.keys(this.item).length === 0) {
          this.$parent.cancelAddNew();
        } else {
          this.$emit('artiklselected', this.item);
        }
      }  
    },
    handleCancel() {
      this.$parent.cancelAddNew();
    },    
    show() {
      this.$refs['artiklPickerModal'].show();
    },
    updateItems: debounce(function() {      
      if (Object.keys(this.item).length === 0 || !this.query.trim().toLowerCase().includes(this.item.Naziv.toLowerCase()))
        artikli.get(this.query).then(response => this.artikli = response.data);        
    }, 500),
    onAddNew(item) {
      this.$emit('artiklselected', item);
      this.$refs['artiklPickerModal'].hide();
      //this.$bvModal.hide('artiklPickerModal');
    }
  },
  components:{ 
    ArtiklModalEdit: ArtiklModalEdit
  }
})
</script>

<style>

</style>