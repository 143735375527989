<template>
  <div>
  <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <!-- <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Buttons</li>
              </ol>
            </div> -->
          </div>
        </div><!-- /.container-fluid -->
      </section>
  <section class="content">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-4">     
            <b-button-group>
                <b-button variant="primary"  v-if="this.selected.length != 0"
                v-on:click="toggle();" title="upali neaktivno / ugasi aktivno">
                  <i class="nav-icon fas fa-edit"></i>
                  Aktiviraj / deaktiviraj</b-button>
              </b-button-group>

            <b-button-group style="margin-left:10px;">
              <b-button variant="outline-secondary" 
              v-if="this.selected.length == 1" 
              v-on:click="$router.push({path: '/fakturarecurrent', name: 'fakturarecurrent.edit', params: { id: selected[0].FakturaRecurrentID }});">              
                <span class="menuButton fas fa-file-invoice"></span>
                <span>Otvori fakturu</span>          
              </b-button>        
            </b-button-group>  
                               

    
          </div>        
        </div>
        <div class="row">
          <div class="col-md-12">  
            &nbsp;
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-body">
                <b-table hover selectable :items="items_filtered" :fields="fields" ref="selectableTable" 
                select-mode="multi"
                primary-key="FakturaID"
                @row-selected="onRowSelected"
                :per-page="perPage"            
               :sort-by.sync="sort_columnName"
                :sort-desc.sync="sort_direction"
                :no-sort-reset="true"
                :current-page="currentPage"
                >
                
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true"><i class="fas fa-check"></i></span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                  <!-- <template #cell(FakturaID)="data">
                    <b class="text-info">{{ data.value }}</b>, <b>{{ data.value }}</b>
                  </template> -->
                  <template #cell()="data">
                    {{ data.value }}
                  </template>
                  <template #cell(Aktivno)="data">
                    <span v-html="data.value"></span>
                  </template>
                </b-table>
              </div>
              <div class="card-footer">
                  <!-- <b-pagination
                    v-model="currentPage"
                    :value="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="selectableTable"
                  ></b-pagination> -->
                  <!-- <b-pagination-nav v-model="currentPage" :link-gen="linkGen" :number-of-pages="pageCount" use-router></b-pagination-nav>                -->
                </div>
            </div>
          </div>
        </div>
      </div>
      
  </section>
  </div>
  </template>
  
  <script>
  import datasource from "../_services/backend";
  const fakturerecurrent = datasource.get("fakturarecurrent");
  import store from "../libs/store";
  import * as utils from "../helpers/index";
  
  var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 
  
  export default {
    name: "FakturaRecurrentBrowse",
    /* created služi kao onInit */
    created() {
      this.getAll();
      utils.EventBus.$on('triggersearch', this.searchHandler);

    },
    mounted() { 
      var tt = this;  //unutar handlera this ne postoji pa ga moraš spremiti vani
      unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
        if (mutation.type == 'updatePoslovnaGodina') {
          
          if (tt.$route.query.page) {
            tt.currentPage = 1;
            tt.$router
            .push({ query: { ...tt.$route.query, page: 1 } })
            .catch(() => {});
          }
  
          tt.getAll();
        }        
      });     
  
    },
    beforeRouteLeave(to,from,next) {
      unsubscribe();
      next();
    },
    data() {
      return {
        query: '',
        columnName: 'Broj',
        descendingSort: true,
        perPage: 20,
        currentPage: this.$route.query.page || 1,
        fields: [
          { key: 'selected', label: '' },
          { key: 'Broj', label: 'Broj', sortable: true, thClass: 'sortableTh' },        
          { key: 'DatumDokumenta', label: 'Datum', formatter: 'datumFormatter', sortable: true, thClass: 'sortableTh' },
          { key: 'Partner', label: 'Partner', sortable: true, class: 'partnerCol', thClass: 'sortableTh' },
          { key: 'Ukupno', label: 'Ukupno', sortable: false, formatter: 'currencyFormatter', class: 'text-right'},

          { key: 'Aktivno', label: 'Aktivno', formatter: 'activeFormatter' },
          { key: 'Dan', label: 'Dan' },
          { key: 'Mjesec', label: 'Mjesec' },
          { key: 'Godina', label: 'Godina' },
          
        ],      
        items: [],
        selected: []
      };
    },
    computed: {
      sort_columnName: {
        get() {
          var stored = localStorage.getItem("fb_sort_columnName")
          if (stored)
            return stored
  
          return this.columnName
        },
        set (value) {
          localStorage.setItem("fb_sort_columnName", value)
          this.columnName = value
        }
      },
      sort_direction: {
        get() {
          var stored = localStorage.getItem("fb_sort_direction")
          if (stored)
            return JSON.parse(stored)
  
          return this.descendingSort
        },
        set (value) {
          localStorage.setItem("fb_sort_direction", value)
          this.descendingSort = value
        }
      },    
      items_filtered() {
  
        const q = this.query.toLowerCase(); 
  
        var bla = [];
        this.items.forEach(function (s) {  
          var skip = false;
  
          if (store.state.sakrijplaceno == "true" && s.DatumPlaceno != null)         
            skip = true;
        
          if (!skip)
            if (q != '' && !(s.Partner.toLowerCase().includes(q)))
              skip = true;
  
          if (!skip)
            bla.push(s);
  
        });
  
        return bla;
      },
      rows() {
        return this.items_filtered.length
      },
      pageCount()  {
        if (this.items_filtered.length == 0)
          return 1;
        else
          return Math.ceil(this.items_filtered.length / 20);
      },
    },
  methods: {
    toggle() {
      const tt = this

      var ids = this.selected.map(function(item) {
        return item.FakturaRecurrentID;
      })
    
      fakturerecurrent.toggle(ids).catch(error => {
        tt.$vToastify.error(error.response.data, "Greška");       
      }).finally(()=>{
        tt.getAll()
        tt.$refs.selectableTable.refresh()
        tt.$vToastify.success("","OK")
      })            

    },
    goEdit() {
      this.$store.state.poslovnagodinanorefreshonnextchange = true; 
      this.$store.commit('updatePoslovnaGodina', this.getYear(this.selected[0].DatumDokumenta));
      this.$router.push({ path: '/faktura/edit', name: 'faktura.edit', params: { id: this.selected[0].FakturaID } });  
    },
    delete() {
      const tt = this

      var msg = "raspored kopiranja?"

      this.$confirm(          
        {
          message: 'Obrisati ' + msg,
          button: {
            no: 'Odustani',
            yes: 'Potvrdi'
          },
          callback: confirm => {
            if (confirm) {
              this.selected.forEach((s) => {
                fakturerecurrent.delete(s.FakturaID).then(response => {
                  if (response.status == 200 && response.data == "OK") {
                    tt.$vToastify.success("Raspored kopiranja za " + s.Broj + " od " + this.datumFormatter(s.DatumDokumenta) + " uspješno obrisan", "OK")
                  } else {
                    tt.$vToastify.error(response.data, "Greška")
                    console.log(response);
                  }            
                }).catch(error => {
                  tt.$vToastify.error("Raspored kopiranja za " + s.Broj + " od " + this.datumFormatter(s.DatumDokumenta) + " nije obrisan " + error.response.data, "Greška");       
                }).finally(()=>{
                  tt.getAll()
                  tt.$refs.selectableTable.refresh()
                })            
              })
            }
          }
        })                  
    },

    showLog() {
      this.$refs['dokumentLogModal'].show(this.selected[0].FakturaID);
    },       
    getYear(value) {
      if (value == null)
        return "";

      return this.$luxon(value, "yyyy");
    },
    datumFormatter: function (value) {
      if (value == null)
        return "";

      return this.$luxon(value, "dd.MM.yyyy.");
    },
    activeFormatter: function (value) {        
      if (value)
        return "<i class='fas fa-check enabled'></i>"
      else
        return "<i class='fas fa-times disabled'></i>"

    },
    currencyFormatter: function (value, columnKey, rowObject) {
      return utils.currencyFormatter(value, rowObject.DatumDokumenta);
    },
    getAll: async function () {
      const { data } = await fakturerecurrent.getAll();
      this.$set(this, 'items', data);
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    searchHandler(query) {
      this.currentPage = 1
      this.query = query
    },
  }
    
};
  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
  .partnerCol {
    max-width: 300px;
  }
  
  .menuButton {
    margin-right: .2rem;
  }
  .menuButtonOthers button {
    margin-right: .5rem;
  }
  .sortableTh {
    cursor: pointer;
  }
  .unsortableTh {
    font-weight: normal;
  }
  </style>

  <style>
    .enabled {
      color: darkgreen;
    }
    .disabled {
      color: darkred;
    }
  </style>
  
  