import axios from 'axios' 

const resource = '/api/partner';

export default {
    getAll() {
      return axios.post(`${resource}/getall`);
    },
    get(query) {
      return axios.post(`${resource}/get`, null, {params:{
        query: query
    }});
    },
    getOne(id) {
        return axios.get(`${resource}/?id=${id}`);
    },
    getBlank() {
        return axios.get(`${resource}/blank`);
    },    
    create(payload) {
        return axios.post(`${resource}/create`, payload);
    },
    update(payload) {
        return axios.put(`${resource}`, payload);
    },
    delete(id) {
        return axios.delete(`${resource}/${id}`)
    },
    
    // MANY OTHER ENDPOINT RELATED STUFFS
};