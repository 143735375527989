import axios from 'axios' 
import store from '../libs/store'

const resource = '/api/artikl';

export default {
  getAll() {
    return axios.post(`${resource}/getall`);
  },  
  get(query) {
    console.log(query);
    return axios.post(`${resource}/get`, null, {params:{
      query: query
  }});
  },
  getAllPaginated(page, count, sortBy, sortDesc, grupa) {
    return axios.post(`${resource}/getallpaginated`, null, {params:{      
      page: page, 
      count: count,
      sortBy: sortBy,
      sortDesc: sortDesc,
      grupa: grupa
  }});
  },    
  getOne(id) {
      return axios.get(`${resource}/?id=${id}`);
  },
  create(payload) {
      return axios.post(`${resource}/create`, payload);
  },
  update(payload) {
      return axios.put(`${resource}`, payload);
  },
  delete(id) {
      return axios.delete(`${resource}/${id}`)
  },
  kartica(id) {
    return axios.post(`${resource}/karticaartikla`, null, {
      params: {
        ArtiklID: id,
        poslovnagodina: store.state.poslovnagodina
      }
    });
  }
    // MANY OTHER ENDPOINT RELATED STUFFS
};