<template>    

    <div class="row">

  <h1>Kartica artikla</h1>

      <div class="col-sm-12">
        <b-table :items="items" :fields="fields">
          <template #cell()="data">
            {{ data.value }}
          </template>
          <template #cell(ID)="data">
            <router-link :to="{ name: 'faktura.edit', params: { id: data.value }}"><i class="far fa-share-square"></i></router-link>                         
          </template> 
        </b-table>
        
      </div>
    </div>

</template>

<script>
import datasource from "../_services/backend";
import store from "../libs/store";
import * as utils from "../helpers/index";

const artikli = datasource.get("artikl");

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default {
  name: "ArtiklKartica",
  /* created služi kao onInit */
  created() {
    this.getAll();

  },
  mounted() {
    var tt = this;  //unutar handlera this ne postoji pa ga moraš spremiti vani
    unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
      if (mutation.type == 'updatePoslovnaGodina') {
        
        if (tt.$route.query.page) {
          tt.currentPage = 1;
          tt.$router
          .push({ query: { ...tt.$route.query, page: 1 } })
          .catch(() => {});
        }

        tt.getAll();
      }        
    });    

  },
  beforeRouteLeave(to,from,next) {
    unsubscribe();
    next();
  },
  data() {
    return {
      query: '',
      columnName: 'Broj',
      descendingSort: true,
      perPage: 20,
      currentPage: this.$route.query.page || 1,
      fields: [
        
        {key: 'Naziv', label: 'Partner'},
        { key: 'Broj', label: 'Broj' },
        { key: 'DatumDokumenta', label: 'Datum dokumenta', formatter: 'datumFormatter' },
        { key: 'Kolicina', label: 'Količina' },
        {key: 'Iznos', label: 'Iznos', formatter: 'currencyFormatter'},
        {key: 'ID', label: ''},
      ],
      items: [],
      selected: []
    };
  },
  computed: {
    // items_filtered() {
    //   const q = this.query.toLowerCase(); 

    //   if (q == '') {
    //     return this.items;  
    //   }

    //   var bla = [];
    //   this.items.forEach(function(s){  
    //     if (s.Naziv.toLowerCase().includes(q) || (s.Grupa != null && s.Grupa.toLowerCase().includes(q)))
    //       bla.push(s);
    //   });

    //   //this.$set(this, 'items_filtered', bla);
    //   return bla;
    // },     
    rows() {
      return this.items_filtered.length
    },
    pageCount()  {
      if (this.items_filtered.length == 0)
        return 1;
      else
        return Math.ceil(this.items_filtered.length / 10);
    },
    //  currentPage:{
    //   get() {
    //     console.log("currentPage get" + this.$route.query.page || 1);
    //     return this.$route.query.page || 1;
    //   },
    //   set() {
    //     if (!this.handlePageChange) return; 
    //   }       
      // get () {
      //   console.log("currentPage GET");
      //   var b = this.$store.state.selectedPageFaktura;
      //   console.log(b)
      //   return b;
      // },
      // set (value) {
      //   console.log("currentPage SET " + value);
      //   this.$store.commit('selectedPageFaktura', value);
      // }
    //},
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    currencyFormatter: function (value, x, item) {      
      if (item['ValutaNaziv'])
        return "-";
      else
        return utils.currencyFormatter(value, item['ValutaNaziv']);

    },    
    currencyFormatter2: function (value, x, item) {      
      if (item['ValutaNaziv'])
        return utils.currencyFormatter2(value, item['ValutaNaziv']);
      else
        return "-";
      
    },
    datumFormatter: function(value) {
      if (value == null)
        return "";

      return this.$luxon(value, "dd.MM.yyyy.");
    },
    getAll: async function() {
      const { data } = await artikli.kartica(this.$route.params.id);
      this.$set(this, 'items', data);
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    searchHandler(query) {
      this.currentPage = 1;
      this.query = query;
    }
  }
};

</script>