import axios from 'axios' 
import store from '../libs/store'

const resource = '/api/fakturarecurrent';

export default {
  getAll() {
    return axios.post(`${resource}/getall`, null, {params:{
      poslovnagodina: store.state.poslovnagodina
    }});
  },
  getOne(id) {
    // var poslovnagodina = store.state.poslovnagodina;
    return axios.get(`${resource}/?id=${id}&poslovnagodina=${store.state.poslovnagodina}`);
  },
  create(payload) {
    return axios.post(`${resource}/create`, payload);
  },
  update(payload) {
    return axios.put(`${resource}/`, payload);
  },
  toggle(ids) {
    ids = ids.join(",");
    return axios.post(`${resource}/toggle`, null, {params:{
      ids
    }});
  },    
};