<template>
  <ArtiklModalEdit ref="artikleditComponent" :isModal="false" @artiklsaved="onsaved"></ArtiklModalEdit>
</template>

<script>
//import datasource from "../_services/backend";

//import store from "../libs/store";

import ArtiklModalEdit from "./ArtiklModalEdit.vue"

// import { debounce, cloneDeep, tap, set } from 'lodash'
//import { debounce } from 'lodash'

export default({
    name: 'ArtiklEdit',
    mounted() {

        // if (this.$route.params.id == "-1") {

        // } else {

        // }

    },
    computed: {

    },
    data() {
        return {
          partnerquery: '',
          partneri: [],
          item: {},
          partner: {},
          query: '',
          dpoptions: {
            useCurrent:false,
            format: 'DD.MM.YYYY.',
            locale: 'hr-HR',
          }
        }
    },
    methods: {
      onsaved() {
        this.$router.push({path: '/artikl'});
      }
    },
    components:{ 
      ArtiklModalEdit: ArtiklModalEdit
    }
})
</script>
<style scoped>
.menuButton {
  margin-right: .2rem;
}
.menuButtonOthers button {
  margin-right: .5rem;
}
</style>