import axios from 'axios';

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user2'));

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function jwtInterceptor() {
    axios.interceptors.request.use(request => {
        const user = JSON.parse(localStorage.getItem('user2'));

        if (user !== null) {
            const isLoggedIn = user.token;

            if (isLoggedIn) {
                request.headers.common.Authorization = `Bearer ${user.token}`;
            }    
        }

        return request;
    });
}

export function loggedoutInterceptor() {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        localStorage.removeItem('user2');
      }
    })
}