<template>
  <section class="content">
    <div v-if="this.pdvr.length != 0" class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-9">
        <div class="card">
          <div class="card-header">
            PDV
          </div>
          <div class="card-body">
            <table>
              <tr>
                <td style="min-width:220px">Naziv</td>
                <td style="min-width:220px">{{pdvr[0].Naziv}}</td>
                <td >{{pdvr[1].Naziv}}</td>
              </tr>
              <tr>
                <td>Razdoblje</td>
                <td>{{datumFormatter(pdvr[0].DatumOd)}} - {{datumFormatter(pdvr[0].DatumDo)}}</td>
                <td>{{datumFormatter(pdvr[1].DatumOd)}} - {{datumFormatter(pdvr[1].DatumDo)}}</td>
              </tr>
              <tr>
                <td>Oporezivo</td>
                <td>{{currencyFormatterPDVr(pdvr[0].oporezivo, pdvr[0])}}</td>
                <td>{{currencyFormatterPDVr(pdvr[1].oporezivo, pdvr[1])}}</td>
              </tr>
              <tr>
                <td>Naplaćen PDV</td>
                <td>{{currencyFormatterPDVr(pdvr[0].faktura, pdvr[0])}}</td>
                <td>{{currencyFormatterPDVr(pdvr[1].faktura, pdvr[1])}}</td>
              </tr>       
              <tr>
                <td>Plaćen PDV</td>
                <td>{{currencyFormatterPDVr(pdvr[0].ura, pdvr[0])}}</td>
                <td>{{currencyFormatterPDVr(pdvr[1].ura, pdvr[1])}}</td>
              </tr>              
              <tr>
                <td>{{pdvstanje}}</td>
                <td>{{currencyFormatterPDVr(Math.abs(pdvr[0].razlika), pdvr[0])}}</td>
                <td>{{currencyFormatterPDVr(Math.abs(pdvr[1].razlika), pdvr[1])}}</td>
              </tr>                  
            </table>
          </div>
        </div>
      </div>
    </div>    
    <div v-if="this.faktureizvanvalute.length > 1" class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-9">
        <div class="card">
          <div class="card-header">
            Fakture izvan valute
          </div>
          <div class="card-body">
            <b-table :items="faktureizvanvalute" :fields="fields" >
              <template #cell()="data">
                {{ data.value }}
              </template>
              <template #cell(ID)="data">
                <router-link title="otvori fakturu" v-if="faktureizvanvalute[data.index].Broj != null" :to="{ name: 'faktura.edit', params: { id: data.value }}"><i class="far fa-share-square"></i></router-link>                         
              </template>       
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.ponudeizvanvalute.length > 1" class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-9"> 
        <div class="card">
          <div class="card-header">
            Ponude izvan valute
          </div>
          <div class="card-body">
            <b-table :items="ponudeizvanvalute" :fields="fields" v-if="this.ponudeizvanvalute.length != 0">
              <template #cell()="data">
                {{ data.value }}
              </template>
              <template #cell(Broj)="data">
                {{ data.value }} <div title="Ponuda nije prihvaćena" v-if="ponudeizvanvalute[data.index].Broj != null" style="float: right; cursor:pointer; color: rgb(10, 88, 202)" v-on:click="markFailed(data.index)"><i class="fas fa-ban"></i></div>
              </template>
              <template #cell(ID)="data">               
                <router-link title="otvori ponudu" v-if="ponudeizvanvalute[data.index].Broj != null" :to="{ name: 'ponuda.edit', params: { id: data.value }}"><i class="far fa-share-square"></i></router-link>                
              </template>
            </b-table>
          </div>
        </div>          
      </div>
    </div>
  </section>
</template>¨

        
<script>
import datasource from "../_services/backend";

const dashboard = datasource.get("dashboard");
const ponude = datasource.get("ponuda");

import moment from 'moment';
import store from "../libs/store";
import * as utils from "../helpers/index";

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default {
  name: "Dashboard",
  /* created služi kao onInit */
  created() {
    this.getAll();
  },
  mounted() {
    var tt = this;  //unutar handlera this ne postoji pa ga moraš spremiti vani
    unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
      if (mutation.type == 'updatePoslovnaGodina') {
        
        if (tt.$route.query.page) {
          tt.currentPage = 1;
          tt.$router
          .push({ query: { ...tt.$route.query, page: 1 } })
          .catch(() => {});
        }

        tt.getAll();
      }        
    });    
  },  
  beforeRouteLeave(to,from,next) {
    unsubscribe();
    next();
  },  
  data() {
    return {
      faktureizvanvalute: [],
      ponudeizvanvalute: [],
      pdvr: [],
      fields: [
        {key: 'Naziv', label: 'Partner'},
        {key: 'Broj', label: 'Broj'},
        {key: 'Ukupno', label: 'Ukupno', formatter: 'currencyFormatter',  class: 'text-right'},
        {key: 'Broj dana', label: 'Broj dana'},
        { key: 'ID', label: '' },
        // {key: 'DatumDokumenta', visible: false },
      ]
    };
  },
  computed: {
    pdvstanje: function() {
      var t,p

      if (this.pdvr[0]['stanje'] < 0) 
        t = "za povrat"
      else
        t = "za platiti"

      if (this.pdvr[1]['stanje'] < 0) 
        p = "za povrat"
      else
        p = "za platiti"        

      return "PDV " + t + "/" + p;
    }
  },
  methods: {
    markFailed(index) { 
      var id = this.ponudeizvanvalute[index].ID

      ponude.markFailed(id).then(response => {
        if (response.status != 200) {
          console.log(response);
        }            
      }).then(() => {
        this.getAll();
      }).catch(error => {
        this.$vToastify.error("bork " + error.response.data, "Greška");       
      });

      
    },
    getAll: async function() {
      const { data } = await dashboard.get();
      this.$set(this, 'ponudeizvanvalute', data.ponudeizvanvalute ?? []);
      this.$set(this, 'faktureizvanvalute', data.faktureizvanvalute ?? []);
      this.$set(this, 'pdvr', data.pdvr ?? []);
    },
    currencyFormatter: function (value, columnKey, rowObject) {
      if (store.state.poslovnagodina < 2023)
        return utils.currencyFormatter(value, '2021-12-31');
      else
        return utils.currencyFormatter(value, rowObject.DatumDokumenta);
    },
    currencyFormatterPDVr: function (value, rowObject) {
      if (new moment(rowObject.DatumOd).year() < 2023)
        return utils.currencyFormatter(value, '2021-12-31');
      else
        return utils.currencyFormatter(value, '2023-01-01');      
    },    
    datumFormatter: function(value) {
      if (value == null)
        return "";

      return this.$luxon(value, "dd.MM.yyyy.");
    },
  }
}
</script>