<template>
  <PartnerModalEdit ref="PartnereditComponent" :isModal="false" @partnersaved="onsaved"></PartnerModalEdit>
</template>

<script>
//import datasource from "../_services/backend";

//import store from "../libs/store";

import PartnerModalEdit from "./PartnerModalEdit.vue"

// import { debounce, cloneDeep, tap, set } from 'lodash'
//import { debounce } from 'lodash'

export default({
    name: 'PartnerEdit',
    mounted() {

        // if (this.$route.params.id == "-1") {

        // } else {

        // }

    },
    computed: {

    },
    data() {
        return {
          partnerquery: '',
          partneri: [],
          item: {},
          partner: {},
          query: '',
          dpoptions: {
            useCurrent:false,
            format: 'DD.MM.YYYY.',
            locale: 'hr-HR',
          }
        }
    },
    methods: {
      onsaved() {
        console.log("bork?");
        this.$router.push({path: '/partner'});
      }
    },
    components:{ 
      PartnerModalEdit: PartnerModalEdit
    }
})
</script>
<style scoped>
.menuButton {
  margin-right: .2rem;
}
.menuButtonOthers button {
  margin-right: .5rem;
}
</style>