<template>    
  <b-modal size="md" ref="plaćenoModal" hide-backdrop title="Datum plaćeno"
  @ok="handleOK" @cancel="handleCancel" >

    <div class="row">
      <div class="col-sm-6">
        <date-picker id="DatumPlaćeno" ref="DatumPlaćeno" 
        :value="selected"
        @dp-change="selected = $event.date"
        :config="dpoptions"></date-picker>
      </div>
    </div>


  </b-modal>
</template>

<script>
import moment from "moment";

export default({
  name: 'PlaćenoModal',
  mounted() {        
    
  },
  computed: {

  },
  data() {
    return {
      selected: moment(),
      dpoptions: {
        useCurrent: true,
        format: 'DD.MM.YYYY.',
        locale: 'hr-HR',

      }
    } 
  },
  methods: {

    handleOK() {
      this.$emit('placenoDatumSelected', this.selected);
    },
    handleCancel() {
      this.$parent.cancelAddNew();
    },    
    show() {
      this.$refs['plaćenoModal'].show();
    },

  }
})
</script>

<style>

</style>