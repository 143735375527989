<template>
<div>
<section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>
      </div><!-- /.container-fluid -->
    </section>
<section class="content">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-4">            
            <b-button-group >
              <b-button variant="primary" 
              v-on:click="$router.push({path: '/faktura', name: 'faktura.edit', params: { id: -1}});">
                <i class="nav-icon fas fa-plus"></i>
                Nova Faktura</b-button>
            </b-button-group>
            &nbsp;
            <div class="btn-group">
              <b-button-group>
                <b-dropdown v-if="this.selected.length == 1" 
                :disabled="this.selected.length == 0" 
                v-on:click="$router.push({path: '/faktura', name: 'faktura.edit', params: { id: selected[0].FakturaID}});"
                right split text="Otvori" >
                  <!-- <b-dropdown-item>
                    <router-link :to="{ name: 'faktura.edit', params: { id: selected[0].FakturaID }}">Izmjena</router-link>
                  </b-dropdown-item> -->
                  <b-dropdown-item v-on:click="print">Ispis</b-dropdown-item>
                  <b-dropdown-item v-on:click="email">E-mail</b-dropdown-item>
                  
                  <b-dropdown-item v-if="this.selected[0].DatumPlaceno == null" v-on:click="showPlacenoModal">Plaćeno</b-dropdown-item>
                  <b-dropdown-item v-if="this.selected[0].DatumPlaceno != null" v-on:click="cancelPlaceno">Poništi plaćeno</b-dropdown-item>
                  <b-dropdown-item v-on:click="showUplataModal">Uplata</b-dropdown-item>
                  
                  <b-dropdown-divider></b-dropdown-divider>
                  
                  <b-dropdown-item v-on:click="copy">Kopiraj</b-dropdown-item>
                  <b-dropdown-item v-on:click="copyPonuda">Kopiraj u ponudu</b-dropdown-item>

                  <b-dropdown-divider></b-dropdown-divider>
                  
                  <b-dropdown-item v-on:click="deleteFaktura">Briši</b-dropdown-item>
                  <b-dropdown-item v-on:click="storno">Storniraj</b-dropdown-item>
                  <b-dropdown-item v-on:click="showLog">Log</b-dropdown-item>
                </b-dropdown>
              </b-button-group >

              <b-button-group>
                <b-dropdown v-if="this.selected.length > 1" :disabled="this.selected.length == 0" v-on:click="download" right split text="Download">
                  
                  <b-dropdown-item v-on:click="showPlacenoModal">Plaćeno</b-dropdown-item>
                  
                  <b-dropdown-divider></b-dropdown-divider>
                  
                  <b-dropdown-item v-on:click="copy">Kopiraj</b-dropdown-item>
                  <b-dropdown-item v-on:click="copyPonuda">Kopiraj u ponudu</b-dropdown-item>

                  <b-dropdown-divider></b-dropdown-divider>
                  
                  <b-dropdown-item v-on:click="storno">Storniraj</b-dropdown-item>

                </b-dropdown>
              </b-button-group >
            </div>
        </div>
        <div class="col-sm-5 menuButtonOthers">
          <b-button variant="outline-secondary" @click="print" v-if="this.selected.length == 1">
            <span class="menuButton fas fa-print"></span>
            <span>Ispis</span>          
          </b-button>              
        </div>        
      </div>
      <div class="row">
        <div class="col-md-12">  
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <b-table hover selectable :items="items_filtered" :fields="fields" ref="selectableTable" 
              select-mode="multi"
              primary-key="FakturaID"
              @row-selected="onRowSelected"
              :per-page="perPage"            
             :sort-by.sync="sort_columnName"
              :sort-desc.sync="sort_direction"
              :no-sort-reset="true"
              :current-page="currentPage"
              >
              
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true"><i class="fas fa-check"></i></span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
                <!-- <template #cell(FakturaID)="data">
                  <b class="text-info">{{ data.value }}</b>, <b>{{ data.value }}</b>
                </template> -->
                <template #cell(Ukupno)="data">
                  <template v-if="data.item.uplaceno > 0">
                    {{ data.value }} <br>
                    <span class="text-gray" title="djelomično uplaćeno">{{ currencyFormatter(data.item.uplaceno, null, data.item) }}</span>
                  </template>
                  <template v-else>                  
                  {{ data.value }}
                  </template>                  
                </template>
                <template #cell()="data">
                  {{ data.value }}
                </template>

              </b-table>
            </div>
            <div class="card-footer">
                <!-- <b-pagination
                  v-model="currentPage"
                  :value="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="selectableTable"
                ></b-pagination> -->
                <b-pagination-nav v-model="currentPage" :link-gen="linkGen" :number-of-pages="pageCount" use-router></b-pagination-nav>               
              </div>
          </div>
        </div>
      </div>
    </div>
    
    <PlaćenoModal @placenoDatumSelected="handlePlacenoModalOk" ref="fakturaplaćenoModal"></PlaćenoModal>
    <UplataModal @uplataSubmit="handleUplataModalOk" ref="fakturauplataModal"></UplataModal>
    <EmailModal vrstadokumenta="faktura" ref="emailModal"></EmailModal>
    <DokumentLogModal ref="dokumentLogModal"></DokumentLogModal>

</section>
</div>
</template>

<script>
import datasource from "../_services/backend";
const fakture = datasource.get("faktura");
import store from "../libs/store";
import * as utils from "../helpers/index";

import PlaćenoModal from "./PlaćenoModal.vue";
import UplataModal from "./UplataModal.vue";

import EmailModal from "./EmailModal.vue";
import DokumentLogModal from "./DokumentLogModal.vue"

import moment from 'moment';
import {saveAs} from 'file-saver';

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default {
  name: "FakturaBrowse",
  /* created služi kao onInit */
  created() {
    utils.EventBus.$on('triggersearch', this.searchHandler);
    //utils.EventBus.$on('sakrijPlaceno', this.sakrijPlacenoHandler);

    utils.EventBus.$emit('showsearch')
    utils.EventBus.$emit('showSakrijPlaceno')

    this.getAll();
  },
  mounted() { 
    var tt = this;  //unutar handlera this ne postoji pa ga moraš spremiti vani
    unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
      if (mutation.type == 'updatePoslovnaGodina' || mutation.type == 'updateSakrijPlaceno') {
        
        if (tt.$route.query.page) {
          tt.currentPage = 1;
          tt.$router
          .push({ query: { ...tt.$route.query, page: 1 } })
          .catch(() => {});
        }

        tt.getAll();
      }        
    });     

  },
  beforeRouteLeave(to,from,next) {
    unsubscribe();
    next();
  },
  data() {
    return {
      query: this.$parent.searchInput,
      columnName: 'Broj',
      descendingSort: true,
      perPage: 20,
      currentPage: this.$route.query.page || 1,
      fields: [
        { key: 'selected', label: '' },
        {key: 'Broj', label: 'Broj', sortable: true, thClass: 'sortableTh'},        
        {key: 'Partner', label: 'Partner', sortable: true, class: 'partnerCol', thClass: 'sortableTh'},
        {key: 'DatumDokumenta', label: 'Datum', formatter: 'datumFormatter', sortable: true, thClass: 'sortableTh' },
        {key: 'DatumValute', label: 'Datum valute', formatter: 'datumFormatter', sortable: true, thClass: 'sortableTh'},
        {key: 'DatumPlaceno', label: 'Plaćeno', formatter: 'datumFormatter', sortable: true, thClass: 'sortableTh'},
        {key: 'Iznos', label: 'Iznos', formatter: 'currencyFormatter', class: 'text-right', thClass: 'unsortableTh'},
        {key: 'IznosRabat', label: 'Iznos rabat', formatter: 'currencyFormatter', class: 'text-right', thClass: 'unsortableTh'},
        {key: 'IznosPDV', label: 'Iznos PDV', formatter: 'currencyFormatter', class: 'text-right', thClass: 'unsortableTh'},
        {key: 'Ukupno', label: 'Ukupno', formatter: 'currencyFormatter', class: 'text-right', thClass: 'unsortableTh'},
      ],      
      items: [],
      selected: []
    };
  },
  computed: {
    sort_columnName: {
      get() {
        var stored = localStorage.getItem("fb_sort_columnName")
        if (stored)
          return stored

        return this.columnName
      },
      set (value) {
        localStorage.setItem("fb_sort_columnName", value)
        this.columnName = value
      }
    },
    sort_direction: {
      get() {
        var stored = localStorage.getItem("fb_sort_direction")
        if (stored)
          return JSON.parse(stored)

        return this.descendingSort
      },
      set (value) {
        localStorage.setItem("fb_sort_direction", value)
        this.descendingSort = value
      }
    },    
    items_filtered() {

      const q = this.query.toLowerCase(); 

      var bla = [];
      this.items.forEach(function (s) {  
        var skip = false;

        if (store.state.sakrijplaceno == "true" && s.DatumPlaceno != null)         
          skip = true;
      
        if (!skip)
          if (q != '' && !(s.Partner.toLowerCase().includes(q) || s.TekstDodatno.toLowerCase().includes(q)))
            skip = true;

        if (!skip)
          bla.push(s);

      });

      return bla;
    },
    rows() {
      return this.items_filtered.length
    },
    pageCount()  {
      if (this.items_filtered.length == 0)
        return 1;
      else
        return Math.ceil(this.items_filtered.length / 20);
    },
  },
  methods: {
    deleteFaktura() {
      const tt = this

      var msg
      if (tt.selected.length == 1)
        msg = "fakturu?"
      else
        msg = tt.selected.length + " faktura?"

      this.$confirm(          
        {
          message: 'Obrisati ' + msg,
          button: {
            no: 'Odustani',
            yes: 'Potvrdi'
          },
          callback: confirm => {
            if (confirm) {
              this.selected.forEach((s) => {
                fakture.delete(s.FakturaID).then(response => {
                  if (response.status == 200 && response.data == "OK") {
                    tt.$vToastify.success("Dokument broj " + s.Broj + " uspješno obrisan", "OK")
                  } else {
                    tt.$vToastify.error(response.data, "Greška")
                    console.log(response);
                  }            
                }).catch(error => {
                  tt.$vToastify.error("Dokument broj " + s.Broj + " nije obrisan " + error.response.data, "Greška");       
                }).finally(()=>{
                  tt.getAll()
                  tt.$refs.selectableTable.refresh()
                })            
              })
            }
          }
        })                  
    },
    download() {
      var ids = this.selected.map(function(item) {
        return item.FakturaID;
      })  
      ids = Object.values(ids)

      fakture.download({ids}).then(response => {
        saveAs(response.data, "fakture" + moment().unix() + ".zip");
      })

    },
    print() {
      window.open("/api/faktura/print?id=" + this.selected[0].FakturaID + "&token=" + JSON.parse(localStorage.getItem('user2')).token, "_blank");
    },
    storno() {
      var tt = this; 

      var ids = this.selected.map(function(item) {
        return item.FakturaID;
      })

      fakture.storno(ids).then(response => {
        if (response.status == 200) {
          this.$vToastify.success("Dokumenti uspješno stornirani", "OK")
        } else {
          console.log(response);
        }            
      }).then(() => {
        tt.getAll().then(() => this.$refs.selectableTable.refresh())
      }).catch(error => {
        this.$vToastify.error("bork " + error.response.data, "Greška");       
      });

    },    
    copy() {
      this.$store.commit('updatePoslovnaGodina', new Date().getFullYear());   

      var tt = this; 

      var ids = this.selected.map(function(item) {
        return item.FakturaID;
      })

      fakture.copy(ids).then(response => {
        if (response.status == 200) {
          this.$vToastify.success("Dokumenti uspješno kopirani", "OK")
        } else {
          console.log(response);
        }            
      }).then(() => {
        tt.getAll().then(() => this.$refs.selectableTable.refresh())
      }).catch(error => {
        this.$vToastify.error("bork " + error.response.data, "Greška");       
      });

    },
    email() {
      this.$refs['emailModal'].show();
      setTimeout(() => this.$refs['emailModal'].$refs['partneremail'].focus(), 500);
    },    
    showLog() {
      this.$refs['dokumentLogModal'].show(this.selected[0].FakturaID);
    },       
    copyPonuda() {
      this.$store.commit('updatePoslovnaGodina', new Date().getFullYear());   

      var ids = this.selected.map(function(item) {
        return item.FakturaID;
      })

      fakture.copyPonuda(ids).then(response => {
        if (response.status == 200) {
          this.$vToastify.success("Dokumenti uspješno kopirani", "OK")
        } else {
          console.log(response);
        }            
      }).catch(error => {
        this.$vToastify.error("bork " + error.response.data, "Greška");    
      }).finally(() => {
        this.$router.push('/ponuda').then(() => this.getAll())   
      })
      
    },
    showPlacenoModal() {
      this.$refs.fakturaplaćenoModal.show();
    },
    cancelPlaceno() {
      this.$confirm(          
        {
          message: 'Poništiti dataum plaćeno?',
          button: {
            no: 'Odustani',
            yes: 'Potvrdi'
          },
          callback: confirm => {
            if (confirm) {
              var s = this.selected[0];

              fakture.cancelPlaceno(s.FakturaID).then(response => {
                if (response.status == 200 && response.data == "OK") {
                  this.$vToastify.success("Dokument broj " + s.Broj + " poništen datum plaćeno", "OK")
                } else {
                  this.$vToastify.error(response.data, "Greška")
                  console.log(response);
                }            
              }).catch(error => {
                this.$vToastify.error(error.response.data, "Greška");       
              }).finally(()=>{
                this.getAll()
                this.$refs.selectableTable.refresh()
              })            
            }
          }
        })
    },
    showUplataModal() {
      this.$refs.fakturauplataModal.id = this.selected[0].FakturaID;
      this.$refs.fakturauplataModal.show();
    },    
    handlePlacenoModalOk(datum) {

      this.selected.forEach((s) => {
        fakture.markPlaceno(s.FakturaID, moment(datum).toISOString()).then(response => {
          if (response.status == 200) {
            this.$vToastify.success("Dokument broj " + s.Broj + " označen kao plaćen", "OK")
          } else {
            console.log(response);
          }            
        }).catch(error => {
          this.$vToastify.error("Dokument broj " + s.Broj + " nije označen kao plaćen " + error.response.data, "Greška");       
        }).finally(()=>{
          this.getAll()
          this.$refs.selectableTable.refresh()
        })
      });

    },
    handleUplataModalOk(datum, iznos) {

      fakture.uplata(this.selected[0].FakturaID, moment(datum).toISOString(), iznos).then(response => {
        if (response.status == 200) {
          this.$vToastify.success("Djelomična uplata", "OK")
        } else {
          console.log(response);
        }            
      }).catch(error => {
        this.$vToastify.error(error.response.data, "Greška");       
      }).finally(()=>{
        this.getAll()
        this.$refs.selectableTable.refresh()
      })

    },    
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    datumFormatter: function(value) {
      if (value == null)
        return "";

      return this.$luxon(value, "dd.MM.yyyy.");
    },
    currencyFormatter: function (value, columnKey, rowObject) {
      return utils.currencyFormatter(value, rowObject.DatumDokumenta);
    },
    getAll: async function () {
      const { data } = await fakture.getAll();
      this.$set(this, 'items', data);
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    searchHandler(query) {    
      this.currentPage = 1
      if (typeof query === 'undefined')
        query = this.$parent.searchInput

      this.query = query
    },
    // sakrijPlacenoHandler() {
    //   this.$set(this, 'currentPage', 1)

    // }
  },
  components: {      
    'UplataModal': UplataModal, 
    'PlaćenoModal': PlaćenoModal,
    'EmailModal': EmailModal,
    'DokumentLogModal': DokumentLogModal
  }
  
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.partnerCol {
  max-width: 300px;
}

.menuButton {
  margin-right: .2rem;
}
.menuButtonOthers button {
  margin-right: .5rem;
}
.sortableTh {
  cursor: pointer;
}
.unsortableTh {
  font-weight: normal;
}
</style>

