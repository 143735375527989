<template>
<div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1>{{mode}} {{item.Naziv}}</h1>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>

        <div class="row mb-2">
          <div class="col-sm-2">
            <b-button block variant="primary" @click="save">
              <span class="menuButton fas fa-save"></span>
              <span>&nbsp;Spremi</span>          
            </b-button>            
          </div>
          <!-- <div class="col-sm-5 menuButtonOthers">
            <b-button variant="outline-secondary" @click="print" v-if="!isNew">
              <span class="menuButton fas fa-print"></span>
              <span>Ispis</span>          
            </b-button>      
            <b-button variant="outline-secondary" @click="email" v-if="!isNew">
              <span class="menuButton far fa-envelope"></span>
              <span>E-mail</span>          
            </b-button>                      
          </div>      -->
            
        </div>
      </div>
    </section>

    <section class="content">
        <div class="container-fluid">
  
      <div class="row mb-2">
        <div class="col-sm-8">
          <div class="card mr-2 ml-2">
            <div class="card-header">
              Osnovni podaci
            </div>
            <div class="card-body mr-2 ml-2">
              <div class="row mb-2">
                <label for="Naziv">Naziv</label>
                <b-form-input v-model="item.Naziv"></b-form-input>
              </div>
              <div class="row mb-2">
                <label for="Opis">Adresa</label>
                <b-form-textarea v-model="item.Adresa"></b-form-textarea>
              </div>              
              <div class="row mb-2">
                <label for="Mjesto">Mjesto</label>
                <b-form-input v-model="item.Mjesto"></b-form-input>
              </div>              
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2 mr-2">
                    <label for="Cijena">Poštanski broj</label>
                    <b-form-input v-model="item.PttBroj"></b-form-input>
                  </div>                    
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="CijenaValuta">Država</label>
                    <b-form-input v-model="item.Drzava"></b-form-input>
                  </div>                    
                </div>                
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2 mr-2">
                    <label for="Valuta">Valuta</label>
                    <b-form-input v-model="item.Valuta"></b-form-input>
                  </div>                    
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="Porez">Matični broj</label>
                    <b-form-input v-model="item.MB"></b-form-input>
                  </div>                    
                </div>                
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <label for="Marža">OIB</label>
                    <b-form-input v-model="item.OIB"></b-form-input>
                  </div>                    
                </div>                
              </div>

            </div>
          </div>
        </div>

      <div class="row mb-2">
        <div class="col-sm-8">
          <div class="card mr-2 ml-2">
            <div class="card-header">
              Dodatni podaci
            </div>
            <div class="card-body mr-2 ml-2">
              <div class="row mb-2">
                <label for="Regija">Regija</label>
                <b-form-select :options="regije" v-model="item.Regija"></b-form-select>
              </div>
              <div class="row mb-2">
                <label for="KontaktOsoba">Kontakt osoba</label>               
                <b-form-input v-model="item.KontaktOsoba"></b-form-input>
              </div>
              <div class="row mb-2">
                <label for="KontaktRM">Radno mjesto</label>               
                <b-form-input v-model="item.KontaktRM"></b-form-input>
              </div>
              <div class="row mb-2">
                <label for="Email">E-mail</label>               
                <b-form-input v-model="item.Email"></b-form-input>
              </div>
              <div class="row mb-2">
                <label for="ZiroRacun">Žiro račun</label>               
                <b-form-input v-model="item.ZiroRacun"></b-form-input>
              </div>
              <div class="row mb-2">
                <label for="Telefon">Telefon</label>               
                <b-form-input v-model="item.Telefon"></b-form-input>
              </div>              
              <div class="row mb-2">
                <label for="Udaljenost">Udaljenost</label>               
                <b-form-input v-model="item.Udaljenost"></b-form-input>
              </div>              
            </div>
          </div>
        </div>
      </div>

      </div>
    </div><!-- /.container-fluid -->
  </section>

</div>    
</template>

<script>
import datasource from "../_services/backend";
const partneri = datasource.get("partner");

export default({
  name: 'PartnerModalEdit',
  mounted() {        
    if (this.$route.params.id == "-1") {
        partneri.getBlank()
        .then(response => {
          this.$set(this, 'item', response.data); 
        });
    } else {
        partneri.getOne(this.$route.params.id)
        .then(response => {
          this.$set(this, 'item', response.data); 
        });
    }
  },
  computed: {
    mode: {
      get: function() {
        if (this.isNew)
          return 'Novi partner'    
        else 
          return 'Izmjena partnera';
      }
    },
    isNew: {
      get: function(){
        return this.$route.params.id == "-1"
      }
    }    
  },
  data() {
    return {   
      regije: [
        { value: "Grad Zagreb", text: "Grad Zagreb" },
        { value: "Središnja Hrvatska", text: "Središnja Hrvatska"},
        { value: "Slavonija", text: "Slavonija" },
        { value: "Gorska Hrvatska", text: "Gorska Hrvatska" },
        { value: "Primorje", text: "Primorje" }, 
        { value: "Dalmacija", text: "Dalmacija" },
        { value: "Istra", text: "Istra" }
      ],  
      item: {},
    } 
  },
  props: { 
    isModal: Boolean
  },
  methods: {
    onRowSelected(selected) {
      this.item = selected[0];
    },
    handleOK() {
      this.$emit('partnerselected', this.item);
    },
    handleCancel() {
      this.$parent.cancelAddNew();
    },    
    show() {
      this.$refs['partnerPickerModal'].show();
    },
    save() {
      if (this.item.Naziv.length == 0) {
        this.$vToastify.warning("Naziv partnera je obavezan unos", "Poruka");       
        return false; 
      }
      partneri.update({ 'item': this.item }).then(response => {
          if (response.status == 200) {
            this.$vToastify.success("Partner uspješno spremljen", "OK")
//console.log("bork!");
            //this.$emit('partnersaved', response.data);
            if (this.isNew) {
              this.$set(this, 'item', response.data);
              this.$router.replace({path: '/partner', name: 'partner.edit', params: { id: response.data.PartnerID}});
            }
          } else {
            console.log(response);
          }            
        }).catch(error => {
          this.$vToastify.error("Greška prilikom spremanja partnera " + error.response.data, "Greška");       
        });
      },    
  }
})
</script>

<style>

</style>