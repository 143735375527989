import axios from 'axios' 

const resource = '/api/tecaj';

export default {
    get(valutaID) {
      return axios.post(`${resource}/get`, null, {params:{
        valutaID: valutaID
    }});
    }
};