import axios from 'axios' 
import store from '../libs/store'

const resource = '/api';

export default {
  get() {
    return axios.post(`${resource}/dashboard`, null, {
      params: {
        poslovnagodina: store.state.poslovnagodina
      }
    });
  }
};