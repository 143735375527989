import Artikl from './Artikl';
import Faktura from './Faktura';
import FakturaRecurrent from './FakturaRecurrent';
import FakturaStavka from './FakturaStavka';
import Partner from './Partner';
import Ponuda from './Ponuda';
import PonudaStavka from './PonudaStavka';
import Invoice from './Invoice';
import InvoiceStavka from './InvoiceStavka';
import UlazniRacun from './UlazniRacun';
import Tecaj from './Tecaj';
import Dashboard from './Dashboard';
import Log from './Log';

import DokumentUpload from './DokumentUpload';

const backends = {
  'dashboard': Dashboard,
  'artikl': Artikl,
  'faktura': Faktura,
  'fakturarecurrent': FakturaRecurrent,
  'fakturastavka': FakturaStavka,
  'invoice': Invoice,
  'invoicestavka': InvoiceStavka,
  'partner': Partner,
  'ponuda': Ponuda,
  'ponudastavka': PonudaStavka,
  'ulazniracun': UlazniRacun,
  'tecaj': Tecaj,
  'log': Log,
  'dokumentupload': DokumentUpload
}

export default {
    get: name => backends[name]
};
