<template>
<div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{mode}} {{item.Broj}}</h1>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>

        <div class="row mb-2">
          <div class="col-sm-2">
            <b-button block variant="primary" @click="save">
              <span class="menuButton fas fa-save"></span>
              <span>&nbsp;Spremi</span>          
            </b-button>            
          </div>
          <div class="col-sm-7 menuButtonOthers">
            <b-button variant="outline-secondary" @click="print" v-if="!isNew">
              <span class="menuButton fas fa-print"></span>
              <span>Ispis</span>          
            </b-button>      
            <b-button variant="outline-secondary" @click="email" v-if="!isNew">
              <span class="menuButton far fa-envelope"></span>
              <span>E-mail</span>          
            </b-button>
            <b-button variant="outline-secondary" @click="dokumentupload">
              <span class="menuButton fas fa-paperclip"></span>
              <span>Dodaj privitak</span>          
            </b-button>                
            <b-button variant="outline-secondary" @click="showLog" v-if="!isNew">
              <span class="fas fa-history"></span>
              <span title="Povijest dokumenta (log)">&nbsp;Log</span>          
            </b-button>                                                 
          </div>     
          <div class="col-sm-2">
        
          </div>                
        </div>

        <div class="row mb-2">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <label for="partner">Partner</label>
                  <vue-typeahead-bootstrap id="partner"
                  placeholder="upišite dio naziva partnera"
                  v-model="partnerquery"
                  :data="partneri"
                  @input="updatePartnerItems"
                  :serializer="item => item.Naziv + ' ' + item.Mjesto"
                  @hit="displayPartner = $event"
              />
              <!-- <br>
              {{ partner.Adresa }} {{ partner.Email }} -->
              </div>
            </div>
          </div>
          <div class="col-sm-4">

          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="DatumDokumenta">Datum dokumenta</label>
                    <date-picker class="col-sm-5 col-md-4" id="DatumDokumenta" ref="DatumDokumenta"
                    :value="item.DatumDokumenta" 
                    @dp-change="updateInvoiceHeaderItem('DatumDokumenta', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-7 col-md-8" for="ValutaDokumenta">Valuta dokumenta</label>
                    <date-picker class="col-sm-5 col-md-4" id="ValutaDokumenta"
                    :value="item.DatumValute" 
                    @dp-change="updateInvoiceHeaderItem('DatumValute', $event.date)"
                    :config="dpoptions"></date-picker>
                  </div>                  
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="Veza">Veza</label>
                    <b-form-input v-model="item.Veza"></b-form-input>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label for="Veza">Dodatni tekst</label>
                    <b-form-textarea rows="3" max-rows="3" v-model="item.TekstDodatno"></b-form-textarea>
                  </div>
                </div>                
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <DokumentPrilog ref="dokumentPrilogComponent"  vrstadokumenta="invoice" v-if="this.files.length != 0" :items="files"></DokumentPrilog>
                  </div>
                </div>            
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">            
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznos">Iznos</label>
                    <b-form-input class="col-sm-7 text-right" id="iznos" readonly :value="currencyFormatter2(item.Iznos)"></b-form-input>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznosrabat">Iznos rabata</label>
                    <b-form-input class="col-sm-7 text-right" id="iznosrabat" readonly :value="currencyFormatter2(item.IznosRabat)"></b-form-input>
                  </div>
                </div>
               <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="iznosporez">Iznos poreza</label>
                    <b-form-input class="col-sm-7 text-right" id="iznosporez" readonly :value="currencyFormatter2(item.IznosPDV)"></b-form-input>
                  </div>
                </div>    
                <div class="row mb-2">
                  <div class="col-sm-12">
                    <label class="col-sm-5" for="ukupno">Ukupno</label>
                    <b-form-input class="col-sm-7 text-right" id="ukupno" readonly :value="currencyFormatter2(item.Ukupno)"></b-form-input>
                  </div>
                </div>           
              </div>
            </div>
        </div>
      </div>
      </div><!-- /.container-fluid -->
    </section>
    <section class="content">
        <div class="container-fluid">
            <InvoiceStavkaEdit ref="stavkeComponent"></InvoiceStavkaEdit>
        </div>
    </section>

    <EmailModal vrstadokumenta="invoice" ref="emailModal"></EmailModal>
    <DokumentLogModal ref="dokumentLogModal"></DokumentLogModal>
    <DokumentUploadModal ref="dokumentUploadModal" @uploadedFile="uploadedFileCallback"  vrstadokumenta="Invoice"></DokumentUploadModal>    
</div>
    
</template>

<script>
import datasource from "../_services/backend";
import * as utils from "../helpers/index";
import InvoiceStavkaEdit from "./InvoiceStavkaEdit.vue"
import EmailModal from "./EmailModal.vue"
import DokumentLogModal from "./DokumentLogModal.vue"
import DokumentUploadModal from "./DokumentUploadModal.vue"
import DokumentPrilog from "./DokumentPrilog.vue"

import store from "../libs/store";

import { debounce } from 'lodash'

import moment from "moment-timezone";

const invoices = datasource.get("invoice");
const partneri = datasource.get("partner");

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default({
    name: 'InvoiceEdit',
    mounted() {
      moment.tz.setDefault("Europe/Zagreb");

      if (this.$route.params.id == "-1") {
        if (new Date().getFullYear() != store.state.poslovnagodina) {
          this.$set(this.item, 'DatumDokumenta', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
          this.$set(this.item, 'DatumValute', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
        } else {
          this.$set(this.item, 'DatumDokumenta', moment().local());
          this.$set(this.item, 'DatumValute',  moment().local());
        }

        this.$set(this.item, 'Veza', '');
        this.$set(this.item, 'TekstDodatno', '');

        this.updateTotal(0,0,0,0);

      } else {
          invoices.getOne(this.$route.params.id)
          .then(response => {
            this.$set(this, 'valutacode', response.data['valutacode']); 
            this.$set(this, 'item', response.data['invoice']); 
            this.$set(this, 'files', response.data['files']); 

            this.item.DatumDokumenta = moment(this.item.DatumDokumenta).local();
            this.item.DatumValute = moment(this.item.DatumValute).local();

            this.partner = response.data['partner'];

            this.partner.toString = function() {
              return this.Naziv + " " + this.Mjesto;
            }
            this.partnerquery = this.partner.toString();

            this.isEditable = response.data['isEditable'];
          });
      }

      unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
        if (mutation.type == 'updatePoslovnaGodina') {          

          if (!this.isNew) {
            this.$router.push("/invoice");
          }

          if (new Date().getFullYear() != store.state.poslovnagodina) {
            this.$set(this.item, 'DatumDokumenta', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
            this.$set(this.item, 'DatumValute', moment(store.state.poslovnagodina + "-12-31", 'YYYY-MM-DD'));
          } else {
            this.$set(this.item, 'DatumDokumenta', moment().local());
            this.$set(this.item, 'DatumValute',  moment().local());
          }
        }        
      });    

    },
    beforeRouteLeave(to,from,next) {
      unsubscribe();
      next();
    },
    computed: {
      displayPartner: {
        get: function() {
          return this.partner;
        },
        set: function(value) {
          this.partner = value;
          this.$set(this.item, "DatumValute", moment().local().add(this.partner.Valuta, 'days'));
        }
      },
      isNew: {
        get: function(){
          return this.$route.params.id == "-1"
        }
      },
      mode: {
        get: function() {
          if (this.isNew)
            return 'Novi invoice'    
          else 
            return 'Pregled invoice';
        }
      }
    },
    data() {
        return {
          valutacode: 'EUR',
          isEditable: true, 
          partnerquery: '',
          partneri: [],
          item: {},
          partner: {},
          query: '',
          dpoptions: {
            useCurrent:false,
            format: 'DD.MM.YYYY.',
            locale: 'hr-HR',
          },
          files: []
        }
    },
    methods: {
      uploadedFileCallback(file) {
        this.files.push(file)
      },
      currencyFormatter: function(value) {
        return utils.currencyFormatter(value);
      },
      currencyFormatter2: function(value) {
        if (isNaN(value))
          return ""; 
        
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: this.valutacode ?? 'EUR',
          minimumFractionDigits: 2,
          currencyDisplay: 'narrowSymbol'
        });

        return (formatter.format(value))
      },
      print() {
        window.open("/api/invoice/print?id=" + this.item.InvoiceID + "&token=" + JSON.parse(localStorage.getItem('user2')).token, "_blank");
      },
      email() {
        this.$refs['emailModal'].show();
        setTimeout(() => this.$refs['emailModal'].$refs['partneremail'].focus(), 500);
      },
      dokumentupload() {
        this.$refs['dokumentUploadModal'].show();
      },          
      showLog() {
        this.$refs['dokumentLogModal'].show();
      },
      save() {       
        if (this.$refs.stavkeComponent.stavke.length == 0) {
          this.$vToastify.warning("Dokument mora imati minimalno jednu stavku", "Poruka");       
          return false; 
        }
        
        this.item.PartnerID = this.displayPartner.PartnerID;

        invoices.update({ 
          invoice: this.item, 
          stavke: this.$refs.stavkeComponent.stavke, 
          files: this.files,
          stavkeDeleted: this.$refs.stavkeComponent.stavkeDeleted }).then(response => {
            if (response.status == 200) {
              this.$vToastify.success("Dokument uspješno spremljen", "OK")

              if (this.isNew) {
                this.$set(this, 'item', response.data);
                this.$router.replace({path: '/invoice', name: 'invoice.edit', params: { id: response.data.InvoiceID}});
                
                invoices.getOne(this.$route.params.id)
                .then(response => {                  
                  this.$set(this, 'item', response.data['invoice']); 
                  this.$refs.stavkeComponent.refresh();
                });   
              }
            } else {
              console.log(response);
            }            
          }).catch(error => {
            this.$vToastify.error("Greška prilikom spremanja dokumenta " + error.response.data, "Greška");       
          });
      },
      updateTotal(iznos, iznosrabat, iznospdv, ukupno) {
        this.$set(this.item, "Iznos", iznos);
        this.item.Osnovica = iznos;
        this.item.IznosRabat = iznosrabat;
        this.item.IznosPDV = iznospdv;
        this.item.Ukupno = ukupno;
      },
      updateInvoiceHeaderItem(key, value) {
        this.item[key] = value;
      },
      updatePartnerItems: debounce(function() {      
        if (Object.keys(this.partner).length === 0 || !this.partnerquery.trim().toLowerCase().includes(this.partner.Naziv.toLowerCase()))
          partneri.get(this.partnerquery).then(response => this.partneri = response.data);        
      }, 500),    
    },
    components: {
      'InvoiceStavkaEdit': InvoiceStavkaEdit,
      'EmailModal': EmailModal,
      'DokumentLogModal': DokumentLogModal,
      'DokumentUploadModal': DokumentUploadModal,
      'DokumentPrilog': DokumentPrilog
    }
})
</script>
<style scoped>
.menuButton {
  margin-right: .2rem;
}
.menuButtonOthers button {
  margin-right: .5rem;
}
</style>