import axios from 'axios' 
import store from '../libs/store'

const resource = '/api/ponuda';

export default {
  getAll() {
      return axios.post(`${resource}/getall`, null, {params:{
          poslovnagodina: store.state.poslovnagodina
      }});
  },
  getOne(id) {
      return axios.get(`${resource}/?id=${id}`);
  },
  create(payload) {
      return axios.post(`${resource}/create`, payload);
  },
  update(payload) {
      return axios.put(`${resource}/`, payload);
  },
  delete(id) {
      return axios.delete(`${resource}/?id=${id}`)
  },
  cancelPlaceno(id) {
    return axios.post(`${resource}/cancelPlaceno?id=${id}`)
  },      
  markPlaceno(id, datum) {
    return axios.post(`${resource}/markPlaceno`, null, {params:{
      id: id,
      datum: datum
  }});
  },
  copy(ids) {
    ids = ids.join(",");
    return axios.post(`${resource}/copy`, null, {params:{
      ids
  }});
  },    
  copyFaktura(ids) {
    ids = ids.join(",");
    return axios.post(`${resource}/copyFaktura`, null, {params:{
      ids
  }});
  },
  email(id, rcpt, subject, body) {
    return axios.post(`${resource}/email`, null, {params:{
      id: id,
      rcpt: rcpt,
      subject: subject,
      body: body
  }});
  },
  print(id) {
    return axios.get(`${resource}/print?id=${id}`, {responseType: 'blob'});
  },
  markFailed(id) {
    return axios.post(`${resource}/markfailed?id=${id}`);
  }
};